import { Injectable } from '@angular/core';
import { StreamProviderService } from './stream-provider.service';
import { of, Subscription } from 'rxjs';
import * as moment from 'moment';
import * as qs from 'qs';
import { HttpClient } from '@angular/common/http';
import { WebsocketService } from '../websocket.service';
import * as _ from 'lodash';
import axios from 'axios';
import { catchError, map } from 'rxjs/operators';
declare var baseUrl: any;
@Injectable({
  providedIn: 'root'
})
export class DatafeedService {

  datafeed: any;
  chartName: any;
  supportedResolutions = ['1', '5', '15', '60', '240', 'D', '7D', '30D'];
  resolutions: any = {
    '1': 1,
    '5': 5,
    '15': 15,
    '30': 15,
    '60': 60,
    '240': 240,
    '1D': 1440,
    '7D': 10080,
    '30D': 43200,
  };
  private lastBarsCache = new Map<string, any>(); // Cache for last bars
  private symbolInfoData = { symbol: '', resolution: '' }; // Track symbol and resolution

  subscription: {
    channel: string;
    uid: any;
    resolution: any;
    symbolInfo: any;
    listener: any;
    lastBar: any;
  } | null = null;
  
  dataChartSub: Subscription = new Subscription();

  constructor(
    public http: HttpClient,
    public websocketService: WebsocketService
  ) { }

  getChartData() {

    const config = {
      supported_resolutions: this.supportedResolutions,
    };

    const datafeed = {

      onReady: (cb: any) => {
        setTimeout(() => cb(config));
      },

      searchSymbols: (
        userInput: any,
        exchange: any,
        symbolType: any,
        onResultReadyCallback: any
      ) => {
        // Implement symbol search logic here if needed
      },

      resolveSymbol: (
        symbolName: any,
        onSymbolResolvedCallback: any,
        onResolveErrorCallback: any,
        extension: any
      ) => {
        const splitData = symbolName.split(/[:/]/);
        const symbolStub = {
          name: symbolName,
          chart: this.chartName,
          description: '',
          type: 'crypto',
          session: '24x7',
          timezone: 'Etc/UTC',
          ticker: symbolName,
          exchange: splitData[0],
          minmov: 1,
          pricescale: 100000000,
          has_intraday: true,
          intraday_multipliers: ['1', '5', '15', '60', '240', '1440', '10080', '43200'],
          supported_resolution: this.supportedResolutions,
          volume_precision: 8,
          data_status: 'streaming',
        };

        setTimeout(() => {
          onSymbolResolvedCallback(symbolStub);
        });
      },

      getBars: (
        symbolInfo: any,
        resolution: any,
        periodParams: any,
        onHistoryCallback: any,
        onErrorCallback: any
      ) => {
        const split_symbol = symbolInfo.name.split(/[:/]/);
        const symbolspair = `${split_symbol[0]}_${split_symbol[1]}`;
      
        const queryString = qs.stringify({
          base: split_symbol[0],
          quote: split_symbol[1],
          ts: periodParams?.firstDataRequest
            ? moment.unix(periodParams?.to).subtract(1, 'm').valueOf()
            : periodParams?.to * 1000,
          interval: this.resolutions[resolution],
          limit: 1000,
          csv: true,
        });

        this.http.get(`${baseUrl}market/qdb-charts?${queryString}`, { responseType: 'text' }).pipe(
          map((data: any) => {
            if (!data || data.trim() === '') {
              onHistoryCallback([], { noData: true });
              return;
            }

            // Split CSV into rows and parse into an array
            const rows = data.split('\n').map((row: string) => row.split(',')).filter((row:any) => row.length >= 6);
      
            if (rows.length === 0) {
              onHistoryCallback([], { noData: true });
              return;
            }
      
            // Map CSV data to TradingView bar format
            const bars = rows.map((row: string[]) => ({
              time: moment(Number(row[5])).valueOf(), // Ensure correct timestamp format
              open: Number(row[0]),
              high: Number(row[1]),
              low: Number(row[2]),
              close: Number(row[3]),
              volume: Number(row[4]),
            }));
      
            // Cache last bar if needed
            if (periodParams?.firstDataRequest) {
              const lastBar = bars[bars.length - 1];
              this.lastBarsCache.set(symbolspair, { lastBar });
            }
      
            onHistoryCallback(bars.reverse(), { noData: false });
          }),
          catchError(error => {
            console.error('Error fetching CSV data:', error);
            onErrorCallback(error);
            return of(null);
          })
        ).subscribe();
      },

      subscribeBars: (
        symbolInfo: any,
        resolution: any,
        onRealtimeCallback: any,
        subscriberUID: any,
        onResetCacheNeededCallback: any
      ) => {
        if (
          this.subscription &&
          this.subscription.symbolInfo.name === symbolInfo.name &&
          this.subscription.resolution === resolution
        ) {
          return;
        }

        if (this.subscription && this.subscription.symbolInfo.name !== symbolInfo.name) {
          const split_symbol = this.subscription.symbolInfo.name.split(/[:/]/);
          const symbolspair = `${split_symbol[0]}_${split_symbol[1]}`
          this.websocketService.chartSocketUnSubscribe(
            symbolspair,
            this.resolutions[this.subscription.resolution]
          );
          this.lastBarsCache.delete(symbolspair);
        }
        
        if (this.subscription && this.subscription.resolution !== resolution) {
          const split_symbol = this.subscription.symbolInfo.name.split(/[:/]/);
          const symbolspair = `${split_symbol[0]}_${split_symbol[1]}`
          this.websocketService.chartSocketUnSubscribe(
            symbolspair,
            this.resolutions[this.subscription.resolution]
          );
        }

        const split_symbol = symbolInfo.name.split(/[:/]/);
        const symbolspair = `${split_symbol[0]}_${split_symbol[1]}`

        const lastBar = this.lastBarsCache.get(symbolspair)?.lastBar;

        // Update symbolInfoData
        if (
          this.symbolInfoData.symbol !== `${symbolspair}` ||
          this.symbolInfoData.resolution !== `${this.resolutions[resolution]}`
        ) {
          this.symbolInfoData.symbol = `${symbolspair}`;
          this.symbolInfoData.resolution = `${this.resolutions[resolution]}`;
          this.websocketService.symbolInfoData.next(this.symbolInfoData);
        }

        // Create new subscription
        this.subscription = {
          channel: `${symbolspair}1`,
          uid: subscriberUID,
          resolution,
          symbolInfo,
          listener: onRealtimeCallback,
          lastBar,
        };

        // Subscribe to new symbol
        this.websocketService.chartSocketSubscribe(
          `${symbolspair}`,
          this.resolutions[resolution]
        );

        // Handle real-time data updates
        this.dataChartSub.unsubscribe();
        this.dataChartSub = this.websocketService.datacharts.subscribe((res: any) => {
          if (!_.isEmpty(res) && res.channel.includes(symbolspair) && res.channel.includes(this.resolutions[resolution])) {
            if (res.data[0] !== undefined && res.data[0] != null && res.data[0].length !== 0) {

              const barData = res.data[0];
              if (this.subscription) {
                if (barData[5] < this.subscription.lastBar?.time) {
                  return;
                }

                const updatedBar = this.updateBar(barData, this.subscription);
                this.subscription.listener(updatedBar);
                this.subscription.lastBar = updatedBar;
              }
            }
          }
      
        });
      },

      unsubscribeBars: (subscriberUID: any) => {
        // this.streamProviderService.streamProvider.unsubscribeBars(subscriberUID);
      },

    };
  
    return datafeed;
  }

  updateBar(barData:any, subscription:any) {
    const lastBar = subscription.lastBar;
    let resolution = subscription.resolution;

    if (resolution.includes('D')) {
      // 1 day in minutes === 1440
      resolution = 1440;
    } else if (resolution.includes('W')) {
      // 1 week in minutes === 10080
      resolution = 10080;
    }

    const coeff = resolution * 60 * 1000;

    const isSameInterval =
      Math.floor(barData[5] / coeff) === Math.floor(lastBar[5] / coeff);
    let _lastBar;

    if (!isSameInterval) {
      // create a new candle, use last close as open **PERSONAL CHOICE**
      _lastBar = {
        open: barData[0],
        high: barData[1],
        low: barData[2],
        close: barData[3],
        volume: barData[4],
        time: barData[5],
      };
    } else {
      // update lastBar candle!
      if (barData[3] < lastBar.low) {
        lastBar.low = barData[3];
      } else if (barData[3] > lastBar.high) {
        lastBar.high = barData[3];
      }

      lastBar.volume += barData[4];
      lastBar.close = barData[3];
      _lastBar = lastBar;
    }
    return _lastBar;
  }
}
