import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { ModalService } from 'src/app/shared/modal';
import { KycRequiredModalService } from './kyc-required-modal.service';

@Component({
  selector: 'app-kyc-required-modal',
  templateUrl: './kyc-required-modal.component.html',
  styleUrls: ['./kyc-required-modal.component.css']
})
export class KycRequiredModalComponent implements OnInit {
  lodash = _;
  userprofile: any;
  langSelected: any;
  lang: any;

  constructor(
    public _httpservice: HttpserviceComponent,
    public kycRequiredModalService: KycRequiredModalService,
    public modalService: ModalService,
    public router: Router,
    public languageService: LanguageService,
    private translate: TranslateService,
    ) {
      this.languageService.language.subscribe((res) => {
        this.lang = res;
        this.langSelected = this.lang.name
        this.translate.use(this.langSelected);
      });

      this.kycRequiredModalService.showKycRequiredModal.subscribe((data: boolean) => {
        if (!data) return;
        this.openWarningModal();
      });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  goToKYC() {
    this.router.navigate(["/profile-verification"]);
    this.closeWarningModal();
  }

  openWarningModal() {
    this.openModal('kycRequirement');
  }

  closeWarningModal() {
    this.closeModal('kycRequirement');
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
}
