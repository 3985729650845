import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import { MarketService } from 'src/app/_httpservices/ws-services/market.service';

@Component({
  selector: 'app-markets-list',
  templateUrl: './markets-list.component.html',
  styleUrls: ['./markets-list.component.css']
})
export class MarketsListComponent implements OnInit, OnDestroy {
  @Input() currentMarket: string = "ALL";
  Markets_Name = [];
  _MarketData: any = [];
  MarketData: any = [];
  allMarketData: any = [];
  MarketDataFav: any;
  loading: any;
  SGMarketdata = "";
  FavPairs: string[] = [];
  favMarketData: any[] = [];
  DefaultMarket: any;
  // DefaultMarketName = "";
  MKTName: any = [0];
  allSettingsSub: Subscription;
  marketsSub: Subscription;
  SelectedMarketData: any;
  parseFloat = parseFloat;

  @ViewChild("Market_button", { static: false }) myDiv!: ElementRef;
  CurrentConvertCurr: string = "USD";
  fiatCurrSub: Subscription;
  currentRoute: any;
  notMarketPage: boolean = false;
  lodash = _;
  public innerWidth: any;
  lang: any;
  currencySettings: any;
  sortPosition: number = 1;
  sortOrder: string = 'desc';
  langSub: Subscription;
  routerSub: Subscription;
  currencySettingsSub: Subscription;
  @Output() closeMarketModal = new EventEmitter<string>();
  cryptoPriceSub: Subscription;
  fiatPriceSub: Subscription;
  CrrBTCRate: any;
  CurrRate: any;
  USDPRICELIST: any = {};

  constructor(
    public websocketservice: WebsocketService,
    private router: Router,
    public _httpservice: HttpserviceComponent,
    private translate: TranslateService,
    public zone: NgZone,
    public marketService: MarketService,
    public languageService: LanguageService,
    public rxToastrService: RxToastrService
    ) {
    this.websocketservice.tickerunSubscribe();
    if (localStorage.getItem("Authorization") !== null) {
      this.getpairfav();
    }
    if (this.router.url.includes('exchange')) {
      this.notMarketPage = true;
    } else {
      this.notMarketPage = false;
    }

    this.langSub = this.languageService.language.subscribe((res: any) => {
      this.lang = res;
    });

    this.routerSub = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.split('/')[1] == 'exchange') {
          this.currentRoute = event.url;
        }
      }
    });

    this.fiatCurrSub = this._httpservice.CurrentFiatCurr.subscribe(
      (data: any) => {
        this.CurrentConvertCurr = data;
      }
    );

    this.cryptoPriceSub = this.websocketservice.CryptoPrice.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        data.forEach((element: any) => {
          if (element[1] > 0) {
            this.USDPRICELIST[element[0]] = element[1];
          }
        });
      }
      this.CrrBTCRate = 1 / this.USDPRICELIST['BTC'];
    });


    this.fiatPriceSub = this.websocketservice.FiatPrice.subscribe((data: any) => {
      if (data !== undefined && data != null && data.length !== 0) {
        data.rate_list.forEach((element: any) => {
          if (element.rate > 0 && element.currency != 'BTC') {
            this.USDPRICELIST[element.currency] = 1 / element.rate;
          }
        });
      }
    });

    this.allSettingsSub = this._httpservice.AllSettings.subscribe(
      (data: any) => {
        if (
          data !== undefined &&
          data != null &&
          data.markets !== undefined &&
          data.markets != null
        ) {
          const markets = data.markets.slice();
          if (data.symbol_groups != null && data.symbol_groups.length > 0) {
            data.symbol_groups.forEach((element: any) => {
              if (
                element != null &&
                element.markets != null &&
                element.markets.length > 0
              ) {
                markets.push(element.label);
                element.markets.forEach((element1: any) => {
                  const index = markets.indexOf(element1);
                  if (index > -1) {
                    markets.splice(index, 1);
                  }
                });
              }
            });
          }

          this.Markets_Name = markets;
          if (this.Markets_Name != null && this.Markets_Name !== undefined) {
            this.MKTName = this.Markets_Name[0];
            this.DefaultMarket = "ALL";
            // this.DefaultMarketName = "ALL";
          }
        }
      }
    );

    this.currencySettingsSub = this._httpservice.CurrencySettings.subscribe(data => {
      if (!_.isEmpty(data)) {
        this.currencySettings = data.sort((a: any, b: any) => a.asset > b.asset || a.sort > b.sort ? 1 : -1);
      }
    });

    this.marketsSub = this.marketService.allMarketData.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.allMarketData = res

        if (this.MarketData != null && this.MarketData.length > 0) {
          this.favMarketData = [];
          this.allMarketData.forEach((x: any) => {
            // console.log(x)
            x[9] = this.convertToFiat(parseFloat(x[3]) * parseFloat(x[1]), x.quote, this.CurrentConvertCurr);
            if (
              this.FavPairs != undefined &&
              this.FavPairs != null &&
              this.FavPairs.includes(x[0])
            ) {
              x.fav =
                this.FavPairs != undefined &&
                this.FavPairs != null &&
                this.FavPairs.includes(x[0]);
              this.favMarketData.unshift(x);
            } else {
              x.fav =
                this.FavPairs != undefined &&
                this.FavPairs != null &&
                this.FavPairs.includes(x[0]);
            }
            // newsearch for pair
            x.ns = x[0].replace("_", "/");
            x.nosp = x[0].replace("_", "");
            this.currencySettings.forEach((el: any) => {
              // console.log(element);
              if (x.base == el.asset) {
                x.baseFullName = el.full_name;
              }
              if (x.quote == el.asset) {
                x.quoteFullName = el.full_name;
              }
            });
          });
        }

        this.MarketClick(this.DefaultMarket);
      }
    });

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    let found = false
    this._httpservice.AllSettings.value.symbol_groups.forEach((el: any) => {
      // console.log(el)
      // return el.find((el2: any) => {
        if (el == this.currentMarket) {
          this.MarketClick(el);
          found = true;
        }
      // })
    });

    if (!found) {
      this.MarketClick(this.currentMarket);
    }
  }

  convertToFiat(rate: any, currencyName?: any, CurrentConvertCurr?: any): any {
    let USD_in_one_convertcurr = 1;
    let USD_Rate = this.USDPRICELIST[currencyName];
    if (USD_Rate == undefined) {
      USD_Rate = 0;
    }
    if (CurrentConvertCurr != 'USD') {
      let USD_ConvertCurr_Rate = this.USDPRICELIST[CurrentConvertCurr.trim()];
      if (USD_ConvertCurr_Rate == undefined) {
        USD_in_one_convertcurr = 0;
      } else {
        USD_in_one_convertcurr = 1 / USD_ConvertCurr_Rate;
      }

    }
    this.CurrRate = rate * (USD_Rate) * USD_in_one_convertcurr;
    return this.CurrRate;
  }

  trackByFnMarket(index: any, Xmarketdata: any) {
    return Xmarketdata[0]; // or item.id
  }

  GetOrders(marketdetail: any) {
    this.SelectedMarketData = marketdetail;
    if (
      marketdetail != undefined &&
      marketdetail[0] != undefined &&
      marketdetail[0] != null &&
      marketdetail[0]?.toString().trim() != ""
    ) {
      this.router.navigate(['exchange', `${marketdetail.base}-${marketdetail.quote}`]);
      this.websocketservice.contractChanged(
        marketdetail.base + "_" + marketdetail.quote
      );
      this.closeMarketModal?.emit('closeMarketModal');
    }
  }

  MarketClick(market: any) {
    // this.DefaultMarketName = market;
    this.DefaultMarket = market;
    this.MarketData = [];

    // if (this.SGMarketdata != '') {
    //   return;
    // }

    if (this.DefaultMarket == 'ALL') {
      this.MarketData = this.allMarketData;
      return;
    }

    if (this.DefaultMarket == 'Favourite') {
      this.MarketData = this.allMarketData.filter((item: any) => {
        if (this.FavPairs.includes(item[0])) {
          return item
        }
      });
      return;
    }

    const merketsGroup = this._httpservice.AllSettings.value.symbol_groups.find(
      (x: any) => x == market
    );

    // console.log(merketsGroup);
    if (merketsGroup == undefined || merketsGroup == null) {
      this.MarketData = this.allMarketData.filter((item: any) => {
        if (item[8] === this.DefaultMarket) {
          return item
        }
      });

    } else {
      this.MarketData = this.allMarketData.filter((item: any) => {
        // console.log(item)
        // return merketsGroup.find((el: any) => {
          if (item[8] === merketsGroup) {
            return item;
          }
        // })
      });
    }
  }

  finddata(item: any) {
    if (item.length == 0) {
      this.MarketClick(this.DefaultMarket);
    } else {
      // this.MarketData = this.allMarketData;
    }
  }

  async savepair(item: any) {
    if (this.FavPairs.includes(item[0])) {
      this.FavPairs.splice(this.FavPairs.indexOf(item[0]), 1);
    } else {
      this.FavPairs.push(item[0]);
    }

    let result2 = await this._httpservice
      .saveFavPair(
        { data: this.FavPairs },
        "Bearer " + localStorage.getItem("Authorization")
      )
      .catch((err) => { });
    if (result2 == undefined || result2 == null) {
      return;
    }
    this.rxToastrService.showRxToastr('', result2.Message, '', 'success');
    this.getpairfav();
  }

  async getpairfav() {
    let results = await this._httpservice
      .GetFavPair("Bearer " + localStorage.getItem("Authorization"))
      .catch((err) => { });
    if (results == undefined || results == null) {
      return;
    }

    this.FavPairs = results.cus_fields;
    if (this.MarketData != null && this.MarketData.length > 0) {
      this.favMarketData = [];
      this.MarketData.forEach((x: any) => {
        if (
          this.FavPairs != undefined &&
          this.FavPairs != null &&
          this.FavPairs.includes(x[0])
        ) {
          x.fav =
            this.FavPairs != undefined &&
            this.FavPairs != null &&
            this.FavPairs.includes(x[0]);
          this.favMarketData.unshift(x);
        } else {
          x.fav =
            this.FavPairs != undefined &&
            this.FavPairs != null &&
            this.FavPairs.includes(x[0]);
        }
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }


  ngOnDestroy(): void {
    this.langSub.unsubscribe();
    this.routerSub.unsubscribe();
    this.fiatCurrSub.unsubscribe();
    this.marketsSub.unsubscribe();
    this.allSettingsSub.unsubscribe();
    this.currencySettingsSub.unsubscribe();
  }

  sortCol(position: number) {
    if (position !== this.sortPosition) {
      this.sortPosition = position;
      this.sortOrder = 'desc';
      return;
    }

    if (position == this.sortPosition) {
      if (this.sortOrder == 'asc') {
        this.sortOrder = 'desc';
      } else {
        this.sortOrder = 'asc';
      }
    }

  }

}


@Pipe({
  name: "orderByMarket",
})
export class orderByMarketPipe implements PipeTransform {
  transform(value: any[], sortKey: any, sortOrder: string): any[] {
    sortOrder = sortOrder && (sortOrder.toLowerCase() as any);

    if (!value || (sortOrder !== 'asc' && sortOrder !== 'desc')) return value;

    let numberArray = [];
    let stringArray = [];

    if (sortKey < 0) {
      numberArray = value.filter(item => typeof item === 'number').sort();
      stringArray = value.filter(item => typeof item === 'string').sort();

    } else {
      numberArray = value.filter(item => typeof item[sortKey] === 'number').sort((a, b) => a[sortKey] - b[sortKey]);
      stringArray = value
        .filter(item => typeof item[sortKey] === 'string')
        .sort((a, b) => {
          if (a[sortKey] < b[sortKey]) return -1;
          else if (a[sortKey] > b[sortKey]) return 1;
          else return 0;
        });
    }
    const sorted = numberArray.concat(stringArray);
    return sortOrder === 'asc' ? sorted : sorted.reverse();
  }
}
