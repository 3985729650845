import {
  Component,
  OnInit,
  OnDestroy,
  NgZone,
  Output,
  EventEmitter
} from '@angular/core';
import {
  HttpserviceComponent
} from '../../../../_httpservices/API/httpservice.component';
import {
  Subscription
} from 'rxjs';
import 'rxjs/add/observable/interval';
import { WebsocketService } from 'src/app/_httpservices/websocket.service';
import { marketService } from 'src/app/_httpservices/API/market.service';
import * as Enumerable from 'linq-es2015';
import * as _ from 'lodash';
import { OrderbookService } from 'src/app/_httpservices/ws-services/orderbook.service';
import { MarketService } from 'src/app/_httpservices/ws-services/market.service';
import { LanguageService } from 'src/app/_httpservices/API/language.service';
import { DecimalPipe } from '@angular/common';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.css']
})


export class SellComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  subsVal: any;
  _sellorders: any;
  _resbj: any;
  status: any;
  TotalValue = '';
  totalBig: any;
  OrderType = '';
  type_order = 0;
  MarketData: any;
  data: any;
  currencyName = '';
  CurrentMarket = '';
  CurrentCurrency = '';
  SizeCurrency = '';
  walletBalance: any = {
    balance: 0
  };

  TotalVolume: any;
  show = false;
  MarketModel = {
    rate: 'Market Price'
  };

  SellModel: any = {
    side: 2,
    market: '',
    trade: '',
    type: '',
    volume: '',
    rate: '',
    isTrailInPercentage: true,
    timeInForce: 'GTC',
    stop: '',
    total: '',
    trail: '',
    trail_act_price: ''
  };

  SellOrderPayload: any = {};

  alive = false;
  CurrencyWalletBalance: any = { balance: 0 };
  MarketWalletBalance: any = { balance: 0 };

  tradeCalc: any = [
    { per: 25 },
    { per: 50 },
    { per: 75 },
    { per: 100 },
  ];

  minOrderValue: any;
  // minTickSize: any;

  len: number = 0;
  trade_setting: any;

  TotalPlace: any;
  rate_vaidate = false;
  volume_vaidate = false;
  total_vaidate = false;
  stop_vaidate = false;
  validate_condition = false;
  lasttrade_condition = false;
  trail_vaidate = false;
  PlaceOrder_processing = false;
  TradeAllowance: boolean = false;
  CurrencySettings: any;
  DefaultDecimal = 8;
  DefaultDecimal2 = '8';
  checkvalue: any;
  fee: any;
  takerfees: any;
  takefee: any;
  minOrderTotal: any;
  Buy: any;
  buyBookDataSub: Subscription;
  buyarrayrate: any;
  CurrentMarketData: any;
  minPrice: any;
  minSize: any;
  minSizePrecision: any;
  minPricePrecision: any;
  pricePlaceholder: any;
  sizePlaceholder: any;
  minOrderPrecision: any;
  selectedPercentage = 0;
  fundsUnavailable: boolean = false;
  lang: any;
  langSub: Subscription;
  allSettingsSub: Subscription;
  @Output() closeFormModal = new EventEmitter<string>();
  selectedTrailType: string ='%';
  minTrail: any;
  minTrailPrecision: any;
  minTrailPlaceholder: any;

  constructor(
    public _httpservice: HttpserviceComponent,
    public market_service: marketService,
    public websocketservice: WebsocketService,
    public zone: NgZone,
    public orderbookService: OrderbookService,
    public marketService: MarketService,
    public languageService: LanguageService,
    private _decimalPipe: DecimalPipe,
    public rxToastrService: RxToastrService,
    private translate: TranslateService
  ) {
    this.buyBookDataSub = this.orderbookService.buyBookData.subscribe((data: any) => {
      if (!_.isEmpty(data)) {
        this.buyarrayrate = data.keys().next().value;
      }
    });

    this.langSub = this.languageService.language.subscribe((res: any) => {
      this.lang = res;
    });

    this.subscription = this.marketService.allMarketData.subscribe((data: any) => {
      this.zone.run(() => {
        this.subscription = this._httpservice.currentMarketPair.subscribe(
          (cury: any) => {
            if (cury.currency != this.CurrentCurrency) {
              this.fundsUnavailable = false;
            }
            this.CurrentMarket = cury.market;
            this.CurrentCurrency = cury.currency;
            this.MarketData = data;
            this.MarketData.forEach((element: any) => {
              var curr = element[0].split('_');
              if (curr[0] == this.CurrentCurrency && curr[1] == this.CurrentMarket) {
                this.CurrentMarketData = element;
              }
            });
          }
        );
      });
    });

    this.ChangeOrderType('Limit');
    websocketservice.subj$.subscribe((val: any) => {
      this.subsVal = val;
      this.SellModel.rate = this.truncate((this.subsVal.key), this.minPricePrecision);
      this.calcTotalByRate()
    });

    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      this.subscription = this._httpservice.currentMarketPair.subscribe(
        (cury: any) => {
          if (!_.isEmpty(cury)) {
            this.CurrentMarket = cury.market;
            this.CurrentCurrency = cury.currency;
            if (data.trade_setting !== undefined && data.trade_setting != null) {
              this.trade_setting = data.trade_setting;
              this.SizeCurrency = this.CurrentCurrency;
              this.trade_setting.forEach((element: any) => {
                if (element.base == this.CurrentCurrency && element.quote == this.CurrentMarket) {
                  this.SellModel.rate = '';
                  this.SellModel.volume = '';
                  this.SellModel.stop = '';
                  this.SellModel.total = '';
                  this.minTrail = 0.1;
                  this.minTrailPrecision = this.decimalCount(this.minTrail);
                  this.minTrailPlaceholder = this.decimalPlace( this.minTrailPrecision);

                  if (element.min_price !== undefined) {
                    this.minPrice = element.min_price;
                    this.minPricePrecision = this.decimalCount(element.min_price);
                    this.pricePlaceholder = this.decimalPlace(this.minPricePrecision);
                  }
                  if (element.min_size !== undefined) {
                    this.minSize = element.min_size;
                    this.minSizePrecision = this.decimalCount(element.min_size);
                    this.sizePlaceholder = this.decimalPlace(this.minSizePrecision);
                  }
                  if (element.min_order_total !== undefined) {
                    this.minOrderValue = element.min_order_total;
                    this.minOrderPrecision = this.decimalCount(element.min_size);
                    this.TotalPlace = this.decimalPlace(this.DefaultDecimal);
                  }
                  if (element.taker_fee !== undefined) {
                    this.fee = element.taker_fee;
                    this.minOrderTotal = element.min_order_total;
                  }
                }
              });
            }
          }
        })
    });
  }

  decimalCount = (num: any) => {
    num = ((this._decimalPipe.transform(num, "1.0-20"))?.toString().replace(/,/g, "")!).toString();
    const numStr = num;
    // String Contains Decimal
    if (numStr.includes('.')) {
      return numStr.split('.')[1].length;
    };
    // String Does Not Contain Decimal
    return 0;
  }

  decimalPlace = (num: any) => {
    const zero = 0;
    if (num > 0) {
      num = ((this._decimalPipe.transform(num, "1.0-20"))?.toString().replace(/,/g, "")!).toString();
      if (num.includes('.')) {
        return zero.toFixed(num.split(".")[1].length);
      } else {
        return zero.toFixed(parseFloat(num));
      }

    }
    return 0;
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  precision(a: any) {
    let e = 1;
    while (Math.round(a * e) / e !== a) {
      e *= 10;
    }
    this.len = Math.round(Math.log(e) / Math.LN10);
    return this.len;
  }

  ngOnInit() {
    this.subscription = this.websocketservice.CurrWalletBalance.subscribe((bal: any) => {
      this.CurrencyWalletBalance.balance = bal.Currency;
      this.MarketWalletBalance.balance = bal.Market;
      if (this.CurrencyWalletBalance.balance > 0) {
        this.fundsUnavailable = false;
      }
    });

    this.subscription = this.websocketservice.CurrURL.subscribe((val: any) => {
      if (val === '/exchange') {
        this.alive = true;
      } else {
        this.alive = false;
      }
    });

    this.subscription = this._httpservice.CurrencySettings.subscribe((data: any) => {
      if (data != null && data !== undefined) {
        this.CurrencySettings = data.sort((a: any, b: any) => a.asset > b.asset || a.sort > b.sort ? 1 : -1);
      }
    });

  }

  truncate(num:any, places:any) {
    if (places === 0) {
      return Math.trunc(num).toString();  // Return the truncated number as a string (no decimal)
    }
  
    const factor = Math.pow(10, places);
    const truncated = Math.trunc(num * factor) / factor;
  
    return truncated.toFixed(places).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1');
  }

  calcPercentage(value: any) {
    if (this.selectedPercentage == 25 && value == 25) {
      this.selectedPercentage = 0;
      return;
    }
    this.selectedPercentage = value;
    if (this.SellModel.rate == 0 || this.SellModel.rate == 'Infinity' || this.SellModel.rate == 'NaN') {
      if (this.buyarrayrate != undefined && this.buyarrayrate != null) {
        this.SellModel.rate = this.buyarrayrate;
        this.ValidateRate_();
      }
    }

    if (this.CurrencyWalletBalance.balance <= 0) {
      this.fundsUnavailable = true;
    } else {
      this.fundsUnavailable = false;
    }
    if (this.SellModel.rate != '' && parseFloat(this.SellModel.rate) > 0 && this.selectedPercentage !== 0) {
      if (this.SizeCurrency == this.CurrentCurrency) {
        if (this.selectedPercentage === 100) {
          this.checkvalue = (100 / 100) * this.CurrencyWalletBalance.balance;
          this.takefee = (((this.checkvalue) * (this.fee / 100)) * parseFloat(this.SellModel.rate));
          this.SellModel.total = this.truncate(((parseFloat(this.SellModel.rate) * this.checkvalue) - this.takefee), this.DefaultDecimal);
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        } else if (this.selectedPercentage === 75) {
          this.checkvalue = (75 / 100) * this.CurrencyWalletBalance.balance;
          this.takefee = (this.checkvalue) * (this.fee / 100) * parseFloat(this.SellModel.rate);
          this.SellModel.total = this.truncate(((parseFloat(this.SellModel.rate) * this.checkvalue) - this.takefee), this.DefaultDecimal);
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        } else if (this.selectedPercentage === 50) {
          this.checkvalue = (50 / 100) * this.CurrencyWalletBalance.balance;
          this.takefee = (this.checkvalue) * (this.fee / 100) * parseFloat(this.SellModel.rate);
          this.SellModel.total = this.truncate(((parseFloat(this.SellModel.rate) * this.checkvalue) - this.takefee), this.DefaultDecimal);
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        } else if (this.selectedPercentage === 25) {
          this.checkvalue = (25 / 100) * this.CurrencyWalletBalance.balance;
          this.takefee = (this.checkvalue) * (this.fee / 100) * parseFloat(this.SellModel.rate);
          this.SellModel.total = this.truncate(((parseFloat(this.SellModel.rate) * this.checkvalue) - this.takefee), this.DefaultDecimal);
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        }
      } else {
        if (this.selectedPercentage === 100) {
          this.SellModel.volume = this.truncate(((100 / 100) * this.MarketWalletBalance.balance), this.DefaultDecimal);
          this.checkvalue = this.SellModel.volume;
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        } else if (this.selectedPercentage === 75) {
          this.SellModel.volume = this.truncate(((75 / 100) * this.MarketWalletBalance.balance), this.DefaultDecimal);
          this.checkvalue = this.SellModel.volume;
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        } else if (this.selectedPercentage === 50) {
          this.SellModel.volume = this.truncate(((50 / 100) * this.MarketWalletBalance.balance), this.DefaultDecimal);
          this.checkvalue = this.SellModel.volume;
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        } else if (this.selectedPercentage === 25) {
          this.SellModel.volume = this.truncate(((25 / 100) * this.MarketWalletBalance.balance), this.DefaultDecimal);
          this.checkvalue = this.SellModel.volume;
          if (this.checkvalue == 'Infinity' || this.checkvalue == 'NaN') {
            this.SellModel.volume = 0;
          } else {
            this.SellModel.volume = this.truncate(this.checkvalue, this.minSizePrecision);
          }
        }

      }
      this.ValidateVolume_();
    }
  }

  // Validate_Decimals(event: any, type: any) {
  //   if (type === 'rate') {
  //     // @ts-ignore
  //     const clipboardData = event.clipboardData || window['clipboardData'];
  //     if (clipboardData != null && clipboardData !== undefined) {
  //       this.SellModel.rate = '';
  //       const pastedText = clipboardData.getData('text');

  //       let prec = this.precision(this.minPrice);
  //       // if (prec == 0) {
  //       //   prec = this.DefaultDecimal;
  //       // }

  //       setTimeout(() => {
  //         const t = pastedText;
  //         this.SellModel.rate = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //         if (prec == 0) {
  //           this.SellModel.rate = Math.trunc(this.SellModel.rate).toString();
  //         }
  //       }, 1);

  //     } else {
  //       if (this.SellModel.rate !== undefined && this.SellModel.rate != null && this.SellModel.rate.trim().length !== 0) {
  //         let prec = this.precision(this.minPrice);
  //         // if (prec == 0) {
  //         //   prec = this.DefaultDecimal;
  //         // }

  //         const t = this.SellModel.rate;
  //         this.SellModel.rate = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //         if (prec == 0) {
  //           this.SellModel.rate = Math.trunc(this.SellModel.rate).toString();
  //         }
  //       }
  //     }

  //   } else if (type === 'volume') {
  //     // @ts-ignore
  //     const clipboardData = event.clipboardData || window['clipboardData'];
  //     if (clipboardData != null && clipboardData !== undefined) {
  //       this.SellModel.volume = '';
  //       const pastedText = clipboardData.getData('text');

  //       let prec = this.precision(this.minPrice);
  //       // if (prec == 0) {
  //       //   prec = this.DefaultDecimal;
  //       // }

  //       setTimeout(() => {
  //         const t = pastedText;
  //         this.SellModel.volume = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //         if (prec == 0) {
  //           this.SellModel.volume = Math.trunc(this.SellModel.volume).toString();
  //         }
  //       }, 1);

  //     } else {
  //       if (this.SellModel.volume !== undefined && this.SellModel.volume != null && this.SellModel.volume.trim().length !== 0) {
  //         let prec = this.precision(this.minPrice);
  //         // if (prec == 0) {
  //         //   prec = this.DefaultDecimal;
  //         // }

  //         const t = this.SellModel.volume;
  //         this.SellModel.volume = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //         if (prec == 0) {
  //           this.SellModel.volume = Math.trunc(this.SellModel.volume).toString();
  //         }
  //       }
  //     }

  //   } else if (type === 'total') {
  //     if (this.SellModel.total !== undefined && this.SellModel.total != null) {
  //       let prec = this.precision(this.minOrderValue);
  //       if (prec == 0) {
  //         prec = this.DefaultDecimal;
  //       }
  //       const t = this.SellModel.total;
  //       this.SellModel.total = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //     }
  //   } else if (type === 'stop') {
  //     // @ts-ignore
  //     const clipboardData = event.clipboardData || window['clipboardData'];
  //     if (clipboardData != null && clipboardData !== undefined) {
  //       this.SellModel.stop = '';
  //       const pastedText = clipboardData.getData('text');

  //       let prec = this.precision(this.minPrice);
  //       // if (prec == 0) {
  //       //   prec = this.DefaultDecimal;
  //       // }

  //       setTimeout(() => {
  //         const t = pastedText;
  //         this.SellModel.stop = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //         if (prec == 0) {
  //           this.SellModel.stop = Math.trunc(this.SellModel.stop).toString();
  //         }
  //       }, 1);

  //     } else {
  //       if (this.SellModel.stop !== undefined && this.SellModel.stop != null && this.SellModel.stop.trim().length !== 0) {
  //         let prec = this.precision(this.minPrice);
  //         // if (prec == 0) {
  //         //   prec = this.DefaultDecimal;
  //         // }

  //         const t = this.SellModel.stop;
  //         this.SellModel.stop = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //         if (prec == 0) {
  //           this.SellModel.stop = Math.trunc(this.SellModel.stop).toString();
  //         }
  //       }
  //     }

  //   } else if (type === 'trail') {
  //     // @ts-ignore
  //     const clipboardData = event.clipboardData || window['clipboardData'];
  //     if (clipboardData != null && clipboardData !== undefined) {
  //       this.SellModel.trail = '';
  //       const pastedText = clipboardData.getData('text');

  //       let prec = this.precision(this.minPrice);
  //       if (prec == 0) {
  //         prec = this.DefaultDecimal;
  //       }

  //       setTimeout(() => {
  //         const t = pastedText;
  //         this.SellModel.trail = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //       }, 1);

  //     } else {
  //       if (this.SellModel.trail !== undefined && this.SellModel.trail != null && this.SellModel.trail.trim().length !== 0) {
  //         let prec = this.precision(this.minPrice);
  //         if (prec == 0) {
  //           prec = this.DefaultDecimal;
  //         }

  //         const t = this.SellModel.trail;
  //         this.SellModel.trail = (t.indexOf('.') >= 0) ? (t.substr(0, t.indexOf('.')) + t.substr(t.indexOf('.'), prec + 1)) : t;
  //       }
  //     }
  //   }
  // }

  ValidateRate_(): boolean {
    if (this.minPricePrecision > 0 && (this.SellModel.rate).toString().charAt(0) == '.') {
      this.SellModel.rate = '0' + this.SellModel.rate
    }

    if (this.OrderType == 'Market') {
      if (this.Buy != undefined && this.Buy != null) {
        this.SellModel.rate = this.buyarrayrate;
        this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.rate);
      }
    } else if (this.OrderType == 'STOPMARKET') {
      this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.stop);
    } else {
      this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.rate);
    }
    if (this.SellModel.rate === undefined || this.SellModel.rate == null || this.SellModel.rate === '' ||
      parseFloat(this.SellModel.rate) < this.minPrice) {
      this.rate_vaidate = true;
      return false;
    } else {
      this.rate_vaidate = false;
      this.ValidateTotal_();
      return true;
    }
  }

  ValidateVolume_(): boolean {
    if (this.minSizePrecision > 0 && (this.SellModel.volume).toString().charAt(0) == '.') {
      this.SellModel.volume = '0' + this.SellModel.volume
    }

    if (this.OrderType == 'Market') {
      if (this.Buy != undefined && this.Buy != null) {
        this.SellModel.rate = this.buyarrayrate;
        this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.rate);
      }
    } else if (this.OrderType == 'STOPMARKET') {
      this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.stop);
    } else {
      this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.rate);
    }
    if (this.SellModel.volume === undefined || this.SellModel.volume == null || this.SellModel.volume === '' ||
      parseFloat(this.SellModel.volume) < this.minSize) {
      this.volume_vaidate = true;
      return false;
    } else {
      this.volume_vaidate = false;
      this.ValidateTotal_();
      return true;
    }
  }

  ValidateTotal_(): boolean {
    if (this.SellModel.total === undefined || this.SellModel.total == null) {
      this.total_vaidate = true;
      return false;
    } else if (parseFloat(this.SellModel.total) < parseFloat(this.minOrderValue)) {
      this.total_vaidate = true;
      return false;
    } else {
      this.total_vaidate = false;
      return true;
    }
  }

  ValidateStop_(): boolean {
    if (this.minPricePrecision > 0 && (this.SellModel.stop).toString().charAt(0) == '.') {
      this.SellModel.stop = '0' + this.SellModel.stop
    }

    if (this.OrderType == 'Market') {
      if (this.Buy != undefined || this.Buy != null) {
        this.SellModel.rate = this.buyarrayrate;
        this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.rate);
      }
    } else if (this.OrderType == 'STOPMARKET') {
      this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.stop);
    } else {
      this.takerfees = parseFloat(this.SellModel.volume) * (this.fee / 100) * parseFloat(this.SellModel.rate);
    }
    if (this.SellModel.stop === undefined || this.SellModel.stop == null || this.SellModel.stop === '' ||
      parseFloat(this.SellModel.stop) < this.minPrice) {
      this.stop_vaidate = true;
      this.validate_condition = false;
      this.lasttrade_condition = false;
      return false;
    } else if (this.OrderType === 'STOPLIMIT' && parseFloat(this.SellModel.stop) < parseFloat(this.SellModel.rate)) {
      this.stop_vaidate = false;
      this.validate_condition = true;
      this.lasttrade_condition = false;
      return false;
    } else if (parseFloat(this.SellModel.stop) > this.CurrentMarketData[1]) {
      this.stop_vaidate = false;
      this.validate_condition = false;
      this.lasttrade_condition = true;
      return false;
    } else {
      this.stop_vaidate = false;
      this.validate_condition = false;
      this.lasttrade_condition = false;
      this.ValidateTotal_();
      return true;
    }
  }

  ValidateTrail_(): boolean {
    if (this.minPricePrecision > 0 && (this.SellModel.trail).toString().charAt(0) == '.') {
      this.SellModel.trail = '0' + this.SellModel.trail
    }

    if (this.SellModel.trail === undefined || this.SellModel.trail == null || this.SellModel.trail === '' ||
      parseFloat(this.SellModel.trail) < this.minPrice) {
      this.trail_vaidate = true;
      return false;
    } else {
      this.trail_vaidate = false;
      return true;
    }
  }

  calcTotal() {
    if ((this.SellModel.rate == '' || (parseFloat(this.SellModel.rate) <= 0))) {
      this.SellModel.volume = '';
      this.SellModel.total = '';
    } else if ((this.SellModel.volume == '' || (parseFloat(this.SellModel.volume) <= 0))) {
      this.SellModel.total = '';
    } else if ((this.SellModel.rate != '' && (parseFloat(this.SellModel.rate) > 0)) && (this.SellModel.volume != '' && parseFloat(this.SellModel.volume) > 0)) {
      this.SellModel.total = this.truncate((parseFloat(this.SellModel.rate) * parseFloat(this.SellModel.volume)), this.DefaultDecimal);
      this.ValidateTotal_();
    }
  }

  calcTotalByRate() {
    if ((this.SellModel.rate == '' || (parseFloat(this.SellModel.rate) <= 0))) {
      this.selectedPercentage = 0;
      this.SellModel.volume = '';
      this.SellModel.total = '';
    } else if ((this.SellModel.volume == '' || (parseFloat(this.SellModel.volume) <= 0))) {
      this.SellModel.total = '';
    } else if ((this.SellModel.rate != '' && (parseFloat(this.SellModel.rate) > 0)) && (this.SellModel.volume != '' && parseFloat(this.SellModel.volume) > 0)) {
      this.SellModel.total = this.truncate((parseFloat(this.SellModel.rate) * parseFloat(this.SellModel.volume)), this.DefaultDecimal);
      this.ValidateTotal_();
    }
  }

  calcTotalByVolume() {
    this.selectedPercentage = 0;
    if ((this.SellModel.rate == '' || (parseFloat(this.SellModel.rate) <= 0))) {
      if (this.buyarrayrate != undefined && this.buyarrayrate != null) {
        this.SellModel.rate = this.buyarrayrate;
        this.ValidateRate_();
        this.calcTotalByVolume();
      }
    } else if ((this.SellModel.volume == '' || (parseFloat(this.SellModel.volume) <= 0))) {
      this.SellModel.total = '';
    } else if ((this.SellModel.rate != '' && (parseFloat(this.SellModel.rate) > 0)) && (this.SellModel.volume != '' && parseFloat(this.SellModel.volume) > 0)) {
      this.SellModel.total = this.truncate((parseFloat(this.SellModel.rate) * parseFloat(this.SellModel.volume)), this.DefaultDecimal);
      this.ValidateTotal_();
    }
  }

  TotalValchange() {
    this.selectedPercentage = 0;
    if (this.SellModel.rate == "" || parseFloat(this.SellModel.rate) <= 0) {
      this.ValidateRate_();
    } else {
      this.SellModel.volume = this.truncate((parseFloat(this.SellModel.total) / parseFloat(this.SellModel.rate)), this.minSizePrecision);
      this.ValidateVolume_();
    }
  }

  ChangeOrderType(_OrderType: string) {

    this.SizeCurrency = this.CurrentCurrency;
    this.trade_setting?.forEach((element: any) => {
      if (element.base == this.CurrentCurrency && element.quote == this.CurrentMarket) {
        this.SellModel.volume = '';
        if (element.min_size !== undefined) {
          this.minSize = element.min_size;
          this.minSizePrecision = this.decimalCount(element.min_size);
          this.sizePlaceholder = this.decimalPlace(this.minSizePrecision);
        }
      }
    });
    this.OrderType = _OrderType;
    if (this.OrderType == 'STOPMARKET') {
      this.SellModel.rate = '';
    }
  }

  allbal() {
    this.SellModel.rate = this.MarketWalletBalance.balance;
  }

  PlaceSellOrder() {
    if (this.OrderType === 'STOPLIMIT') {

      const StopLimitPlaceOrder = {
        side: this.SellModel.side,
        symbol: this.CurrentCurrency + '_' + this.CurrentMarket,
        type: 4,
        size: parseFloat(this.SellModel.volume),
        price: parseFloat(this.SellModel.rate),
        stop: parseFloat(this.SellModel.stop),
        // timestamp: Math.floor(new Date().getTime() / 1000),
        // recvWindow: 10000
      };

      if (this.ValidateStop_() === false || this.ValidateRate_() === false || this.ValidateVolume_() === false ||
        this.ValidateTotal_() === false) {
        return;
      } else {
        this.SellOrderPayload = StopLimitPlaceOrder;
        this.PlaceSellOrders(this.SellOrderPayload);
      }

    } else if (this.OrderType === 'STOPMARKET') {

      const StopMarketPlaceOrder = {
        side: this.SellModel.side,
        symbol: this.CurrentCurrency + '_' + this.CurrentMarket,
        type: 3,
        stop: parseFloat(this.SellModel.stop),
        size: parseFloat(this.SellModel.volume),
        // timeInForce: this.SellModel.timeInForce,
        // timestamp: Math.floor(new Date().getTime() / 1000),
        // recvWindow: 10000
      };

      if (this.ValidateStop_() === false || this.ValidateVolume_() === false) {
        return;
      } else {
        this.SellOrderPayload = StopMarketPlaceOrder;
        this.PlaceSellOrders(this.SellOrderPayload);
      }

    } else if (this.OrderType === 'Trailing') {

      const TrailingPlaceOrder = {
        symbol: this.CurrentCurrency + '_' + this.CurrentMarket,
        side: this.SellModel.side,
        trail_amt: this.SellModel.isTrailInPercentage == false ? this.SellModel.trail: 0,
        trail_perc: this.SellModel.isTrailInPercentage == true ? this.SellModel.trail: 0,
        trail_act_price: parseFloat(this.SellModel.trail_act_price) >= 0 ? parseFloat(this.SellModel.trail_act_price) : 0,
        type: 8,
        size: parseFloat(this.SellModel.volume),
      };

      if (this.ValidateTrail_() === false || this.ValidateVolume_() === false) {
        return;
      } else {
        this.SellOrderPayload = TrailingPlaceOrder;
        this.PlaceSellOrders(this.SellOrderPayload);
      }

    } else if (this.OrderType === 'Market') {
      const MarketPlaceOrder = {
        side: this.SellModel.side,
        symbol: this.CurrentCurrency + '_' + this.CurrentMarket,
        type: 1,
        size: this.SizeCurrency == this.CurrentCurrency ? parseFloat(this.SellModel.volume) : 0,
        quote_amount: this.SizeCurrency == this.CurrentMarket ? parseFloat(this.SellModel.volume) : 0
        // timeInForce: 'FOK',
        // timestamp: Math.floor(new Date().getTime() / 1000),
        // recvWindow: 10000
      };

      if (this.ValidateVolume_() === false) {
        return;
      } else {
        this.SellOrderPayload = MarketPlaceOrder;
        this.PlaceSellOrders(this.SellOrderPayload);
      }

    } else if (this.OrderType === 'Limit') {
      if (this.OrderType === 'Limit' && this.SellModel.timeInForce == 'GTC') {
        this.type_order = 2
      } else if (this.OrderType === 'Limit' && this.SellModel.timeInForce == 'IOC') {
        this.type_order = 7
      } else if (this.OrderType === 'Limit' && this.SellModel.timeInForce == 'FOK') {
        this.type_order = 6
      } else if (this.OrderType === 'Limit' && this.SellModel.timeInForce == 'DO') {
        this.type_order = 5
      }
      const LimitPlaceOrder = {
        side: this.SellModel.side,
        symbol: this.CurrentCurrency + '_' + this.CurrentMarket,
        type: this.type_order,
        size: parseFloat(this.SellModel.volume),
        price: parseFloat(this.SellModel.rate),
        // timeInForce: this.SellModel.timeInForce,
        // timestamp: Math.floor(new Date().getTime() / 1000),
        // recvWindow: 10000
      };

      if (this.ValidateRate_() === false || this.ValidateVolume_() === false || this.ValidateTotal_() === false) {
        return;
      } else {
        this.SellOrderPayload = LimitPlaceOrder;
        this.PlaceSellOrders(this.SellOrderPayload);
      }


    } else {
      return;
    }
  }

  changeSizeCurr(item: any) {
    this.SizeCurrency = item;
    if (this.SizeCurrency == this.CurrentCurrency) {
      this.trade_setting.forEach((element: any) => {
        if (element.base == this.CurrentCurrency && element.quote == this.CurrentMarket) {
          this.SellModel.volume = '';
          if (element.min_size !== undefined) {
            this.minSize = element.min_size;
            this.minSizePrecision = this.decimalCount(element.min_size);
            this.sizePlaceholder = this.decimalPlace(this.minSizePrecision);
          }
        }
      });
    } else {
      this.minSizePrecision = this.decimalCount('0.00000001');
      this.sizePlaceholder = this.decimalPlace(8);
    }
  }

  handleIsTrailInPerc(data:boolean) {
    this.SellModel.isTrailInPercentage = data;
    this.SellModel.trail = 0;
  }

  async PlaceSellOrders(SellOrderPayload: any) {
    this.PlaceOrder_processing = true;

    let result2 = await this.market_service._PlaceOrders(SellOrderPayload).catch(err => {
      this.PlaceOrder_processing = false;
    });
    if (result2 == undefined || result2 == null) {
      return
    }
    this.closeFormModal.emit('closeFormModal');
    this._sellorders = result2;
    if (this._sellorders.filled > 0) {
      const total = this._sellorders.filled + this._sellorders.remaining;
      // tslint:disable-next-line:max-line-length
      this.rxToastrService.showRxToastr('', this.translate.instant('common.sellOrderFilled', {value: this._sellorders.filled, value2: total} ), '', 'success');
    } else {
      this.rxToastrService.showRxToastr('', this.translate.instant('common.success'), '', 'success');
    }
    //  this.websocketservice.RefreshBalance();
    this.PlaceOrder_processing = false;



  }

  advance() {
    this.show = !this.show;
  }

  ngOnDestroy(): void {
    this.alive = false;
    this.subscription.unsubscribe();
    this.buyBookDataSub.unsubscribe();
    this.langSub.unsubscribe();

    if (this.allSettingsSub) {
      this.allSettingsSub.unsubscribe();
    }
  }
}
