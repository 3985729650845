import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { HttpserviceComponent } from 'src/app/_httpservices/API/httpservice.component';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AuthService } from 'src/app/guards/auth.service';
import { LocationStrategy } from '@angular/common';
import { settingService } from 'src/app/_httpservices/API/setting.service';
import * as _ from 'lodash';
import { HttpClient } from '@angular/common/http';
import { RxToastrService } from 'src/app/_httpservices/rx-toastr.service';

export interface countries {
  countryCode: string;
  name: string;
  isdCode: string;
}
@Component({
  selector: 'app-mobile-verify',
  templateUrl: './mobile-verify.component.html',
  styleUrls: ['./mobile-verify.component.css'],
})
export class MobileVerifyComponent implements OnInit, OnDestroy {

  _resbj: any;
  userprofile: any;
  RequestSmsCode_processing = false;
  RequestCode_processing = false;
  mobileVerifyProcessing = false;
  Showotpform = false;
  Showdisotpform = false;
  model = {
    OTP: '',
  };
  dismodel = {
    OTP: '',
  };
  otp: any;
  Mobile: any;
  mobverify = {
    country: '',
    mobile: '',
  };
  OTPErrorMsg = false;
  validate_country = false;
  validate_mobile = false;
  noedit = false;
  countrylist: any;
  currentCountry: any;
  // tslint:disable-next-line: max-line-length
  smsCountDown = 120;
  countDownSMS!: Subscription;
  smsotp_processing = false;

  smsCountDown2 = 120;
  countDownSMS2!: Subscription;
  smsotp_processing2 = false;
  userProfileSub: Subscription = new Subscription();
  RequestDisable_processing: boolean = false;
  allSettingsSub: Subscription = new Subscription();
  subscription: Subscription = new Subscription();
  settings: any;
  lodash = _;
  loadingPhoneVerification: boolean = true;

  constructor(
    public _httpservice: HttpserviceComponent,
    public _settingservice: settingService,
    public router: Router,
    private authService: AuthService,
    private location: LocationStrategy,
    private httpClient: HttpClient,
    public rxToastrService: RxToastrService
  ) {
    this.allSettingsSub = this._httpservice.AllSettings.subscribe((data: any) => {
      if (_.isEmpty(data)) return;
      this.loadingPhoneVerification = true;
      this.settings = data;
    
      const isPhoneVerificationEnabled = _.get(data, 'phone_verification', false);
      const isPhoneVerificationMandatory = _.get(data, 'phone_verification_mandatory', false);
    
      if (isPhoneVerificationEnabled && isPhoneVerificationMandatory) {
        this.userProfileSub = this._httpservice.userProfile.subscribe((data2: any) => {
          if (_.isEmpty(data2)) return;
    
          this.userprofile = data2;
          const mobileStatus = _.get(data2, 'mobileNumberVerificationStatus');
          const accType = _.get(data2, 'accType');
          const adminSession = _.get(data2, 'admin_session', false);
    
          if (mobileStatus === "Not Verified" && accType != 'SubAccount' && adminSession == false) {
            this.loadingPhoneVerification = false;
            this.mobverify.country = this.userprofile.country;
            this.httpClient.get('assets/countries.json').subscribe((data3: any) => {
              this.countrylist = data3;
              if (this.userprofile.country) {
                this.currentCountry = _.find(this.countrylist, { countryCode: this.userprofile.country });
              }
            });
          } else if (mobileStatus === "Verified" && this.authService.isAuthorized()) {
            this.loadingPhoneVerification = true;
            this._httpservice.getRiskRules();
          } else if (this.authService.isAuthorized()) {
            this.loadingPhoneVerification = true;
            this._httpservice.getRiskRules();
          }
        });
      } else {
        this.loadingPhoneVerification = true;
        this._httpservice.getRiskRules();
      }
    });

  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    
  }

  _mobile(): boolean {
    if (this.mobverify.mobile == null || this.mobverify.mobile == '') {
      this.validate_mobile = true;
      return false;
    } else {
      this.validate_mobile = false;
      return true;
    }
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  async getsmsotp() {
    if (this._mobile() == false) {
      return;
    }
    let payload = {
      mobile: this.mobverify.mobile,
    };
    this.RequestSmsCode_processing = true;
    let result = await this._settingservice.getSMSotp(payload).catch((err) => {
      this.RequestSmsCode_processing = false;
      this.noedit = false;
    });
    if (result == undefined || result == null) {
      return;
    }
    this._resbj = result;
    this.RequestSmsCode_processing = false;
    this.Showotpform = true;
    this.noedit = true;
    this.rxToastrService.showRxToastr('', this._resbj.Message, '', 'success');
    this.countDownIntervalSMS();
  }

  validateOTP(): boolean {
    if (_.isEqual(this.model.OTP, '')) {
      this.OTPErrorMsg = true;
      return false;
    } else {
      this.OTPErrorMsg = false;
      return true;
    }
  }

  async sendotp() {
    if (this.validateOTP() == false) {
      return;
    }

    this.otp = this.model.OTP;
    this.mobileVerifyProcessing = true;
    let result = await this._settingservice.SMSotp(this.otp).catch((err) => {
      this.model.OTP = '';
      this.mobileVerifyProcessing = false;
    });
    if (result == undefined || result == null) {
      return;
    }
    this._resbj = result;
    this.mobileVerifyProcessing = false;
    this.Showotpform = false;
    this.rxToastrService.showRxToastr('', this._resbj.Message, '', 'success');
    this.router.navigate(['/exchange']);
    this._httpservice._GetuserProfile();
  }

  async disablenumber() {
    this.RequestCode_processing = true;
    let result = await this._settingservice.disableSMSotp().catch((err) => {
      this.RequestCode_processing = false;
    });
    if (result == undefined || result == null) {
      return;
    }
    this._resbj = result;
    this.RequestCode_processing = false;
    this.Showdisotpform = true;
    this.rxToastrService.showRxToastr('', this._resbj.Message, '', 'success');
    this.countDownIntervalSMS2();
  }

  async disablesendotp() {
    this.otp = this.dismodel.OTP;
    this.RequestDisable_processing = true;
    let result = await this._settingservice
      .SMSotpdisabled(this.otp)
      .catch((err) => {
        this.model.OTP = '';
        this.RequestDisable_processing = false;
      });
    if (result == undefined || result == null) {
      return;
    }
    this.RequestDisable_processing = false;
    this._resbj = result;
    this.Showdisotpform = false;
    this.rxToastrService.showRxToastr('', this._resbj.Message, '', 'success');
    this.router.navigate(['/exchange']);
    this._httpservice._GetuserProfile();
  }

  countDownIntervalSMS() {
    this.smsCountDown = 120;
    this.countDownSMS = timer(0, 1000).subscribe(() => {
      this.smsotp_processing = true;
      if (this.smsCountDown < 1) {
        this.smsCountDown = 0;
        this.countDownSMS.unsubscribe();
        this.smsotp_processing = false;
        return;
      }
      --this.smsCountDown;
    });
  }

  countDownIntervalSMS2() {
    this.smsCountDown2 = 120;
    this.countDownSMS2 = timer(0, 1000).subscribe(() => {
      this.smsotp_processing2 = true;
      if (this.smsCountDown2 < 1) {
        this.smsCountDown2 = 0;
        this.countDownSMS2.unsubscribe();
        this.smsotp_processing2 = false;
        return;
      }
      --this.smsCountDown2;
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.userProfileSub) {
      this.userProfileSub.unsubscribe();
    }
  }
}
