// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-otp-panel {
  width: 100%;
  max-width: 500px;
}
.enableform{
  margin-bottom: 10px;
}
.space {
  display: flex;
  margin-bottom: 5px;
  font-size: 15px;
  font-weight: bold;
}
.kd img{
  max-width: 150px;
}
.w-500{
  width: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/mobile-verify/mobile-verify.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;AACd","sourcesContent":[".mobile-otp-panel {\n  width: 100%;\n  max-width: 500px;\n}\n.enableform{\n  margin-bottom: 10px;\n}\n.space {\n  display: flex;\n  margin-bottom: 5px;\n  font-size: 15px;\n  font-weight: bold;\n}\n.kd img{\n  max-width: 150px;\n}\n.w-500{\n  width: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
