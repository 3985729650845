import { Injectable } from '@angular/core';
import { StreamProviderService } from './stream-provider.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as qs from 'qs';
import { HttpClient } from '@angular/common/http';
import { WebsocketService } from '../websocket.service';
import * as _ from 'lodash';
import axios from 'axios';
declare var baseUrl: any;
@Injectable({
  providedIn: 'root'
})
export class DatafeedService {

  datafeed: any;
  subscription: Subscription = new Subscription;
  chartName: any;
  supportedResolutions = ['1', '5', '15', '60', '240', 'D', '7D', '30D'];
  resolutions: any = {
    '1': 1,
    '5': 5,
    '15': 15,
    '30': 15,
    '60': 60,
    '240': 240,
    '1D': 1440,
    '7D': 10080,
    '30D': 43200,
  };

  constructor(
    public streamProviderService: StreamProviderService,
    public http: HttpClient,
    public websocketService: WebsocketService
  ) { }

  getChartData() {

    const config = {
      supported_resolutions: this.supportedResolutions,
    };

    const datafeed = {

      onReady: (cb: any) => {
        setTimeout(() => cb(config));
      },

      searchSymbols: (userInput: any, exchange: any, symbolType: any, onResultReadyCallback: any) => {
      },

      resolveSymbol: (symbolName: any, onSymbolResolvedCallback: any, onResolveErrorCallback: any) => {
        const splitData = symbolName.split(/[:/]/);
        const symbolStub = {
          name: symbolName,
          chart: this.chartName,
          description: '',
          type: 'crypto',
          session: '24x7',
          timezone: 'Etc/UTC',
          ticker: symbolName,
          exchange: splitData[0],
          minmov: 1,
          pricescale: 100000000,
          has_intraday: true,
          intraday_multipliers: ['1', '5', '15', '60', '240', '1440', '10080', '43200'],
          supported_resolution: this.supportedResolutions,
          volume_precision: 8,
          data_status: 'streaming',
        };

        setTimeout(() => {
          onSymbolResolvedCallback(symbolStub);
        });
      },

      getBars: async (symbolInfo: any, resolution: any, periodParams: any, onHistoryCallback: any, onErrorCallback: any, limit: any) => {
        const split_symbol = symbolInfo.name.split(/[:/]/);
        const queryString = qs.stringify({
          base: split_symbol[0],
          quote: split_symbol[1],
          ts: periodParams?.firstDataRequest
            ? moment
              .unix(periodParams?.to)
              .subtract(1, 'm')
              .valueOf()
            : periodParams?.to * 1000,
          interval: this.resolutions[resolution],
          limit: limit ? limit : 1000,
        });

        try {
          const { data } = await axios({
            url: `${baseUrl}market/charts?${queryString}`,
            method: 'GET',
          });

          if (data.length === 0) {
            onHistoryCallback([], {
              noData: true,
            });
            return;
          }

          const bars = data.map((bar: any) => {
            delete bar.ID;

            return {
              ...bar,
              time: moment(bar.time).valueOf(),
            };
          });

          if (periodParams?.firstDataRequest) {
            const lastBar = bars[0];
            //@ts-ignore
            history[symbolInfo.name] = { lastBar };
          }

          let tempBar = bars.reverse();
          onHistoryCallback(tempBar, {
            noData: false,
          });
        } catch (error) {
          onErrorCallback(error);
        }
      },

      subscribeBars: (symbolInfo: any, resolution: any, onRealtimeCallback: any, subscribeUID: any, onResetCacheNeededCallback: any) => {
        const split_symbol = symbolInfo.name.split(/[:/]/);
        this.websocketService.chartSocketSubscribe(split_symbol, this.resolutions[resolution]);
        this.streamProviderService.streamProvider.subscribeBars(
          symbolInfo,
          resolution,
          onRealtimeCallback,
          subscribeUID,
          onResetCacheNeededCallback,
        );
      },

      unsubscribeBars: (subscriberUID: any) => {
        this.streamProviderService.streamProvider.unsubscribeBars(subscriberUID);
      },

      calculateHistoryDepth: (resolution: any, resolutionBack: any, intervalBack: any) => {
      },

      getMarks: (symbolInfo: any, startDate: any, endDate: any, onDataCallback: any, resolution: any) => {
      },

      getTimeScaleMarks: (symbolInfo: any, startDate: any, endDate: any, onDataCallback: any, resolution: any) => {
      },

      getServerTime: (cb: any) => {
      }

    };

    this.datafeed = datafeed;

    return this.datafeed;
  }
}
