import { Component, Input, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { Subscription, BehaviorSubject, combineLatest } from "rxjs";

import {
  widget,
  IChartingLibraryWidget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
} from "src/assets/charting_library";
import { HttpserviceComponent } from "src/app/_httpservices/API/httpservice.component";
import { DatafeedService } from "src/app/_httpservices/tradingview-services/datafeed.service";
import { WebsocketService } from "src/app/_httpservices/websocket.service";
import * as _ from "lodash";
import { marketService } from "src/app/_httpservices/API/market.service";
import { LanguageService } from "src/app/_httpservices/API/language.service";
import { TranslateService } from "@ngx-translate/core";
import { OrderbookService } from "src/app/_httpservices/ws-services/orderbook.service";

// import datafeed from '../../../assets/TradeViewChart/datafeed';

@Component({
  selector: "app-trade-view-chart",
  templateUrl: "./trade-view-chart.component.html",
  styleUrls: ["./trade-view-chart.component.css"],
})
export class TradeViewChartComponent implements OnInit, OnDestroy, AfterViewInit {
  chartcolorSub: Subscription = new Subscription();
  currentThemeSub: Subscription = new Subscription();
  backgroundColor: string = "";
  backgroundColor2: string = "";
  bidColor: string = "";
  askColor: string = "";
  textColor: string = "";
  borderColor: string = "";
  currentPairsSub: Subscription = new Subscription();
  openOrdersSub: Subscription = new Subscription();
  CurrentCurrency: any;
  CurrentMarket: any;
  ChartData: any;
  themeMode: any;
  ChartInfo = {
    baseCurrency: "",
    quoteCurrency: "",
    interval: 0,
    limit: 0,
    timestamp: 0,
  };

  OpenOrders: any[] = [];
  Cancelthisorder: any = {};
  ChartHistory = new BehaviorSubject<any>([]);
  _tempSymbol = '';
  idToApiObject = new Map();

  private _symbol: ChartingLibraryWidgetOptions["symbol"] = "AAPL";
  private _interval: ChartingLibraryWidgetOptions["interval"] =
    "1h" as ResolutionString;
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = "https://demo_feed.tradingview.com";
  private _libraryPath: ChartingLibraryWidgetOptions["library_path"] =
    "/assets/charting_library/";
  private _chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"] =
    "https://saveload.tradingview.com";
  private _chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"] =
    "1.1";
  private _clientId: ChartingLibraryWidgetOptions["client_id"] =
    "tradingview.com";
  private _userId: ChartingLibraryWidgetOptions["user_id"] = "public_user_id";
  private _fullscreen: ChartingLibraryWidgetOptions["fullscreen"] = false;
  private _autosize: ChartingLibraryWidgetOptions["autosize"] = true;
  private _containerId: ChartingLibraryWidgetOptions["container"] =
    "tv_chart_container";
  private _tvWidget: IChartingLibraryWidget | null = null;

  customStyle: string | undefined;
  pricePrecisionSub: Subscription = new Subscription();
  currentPrecision: any;
  subscription!: Subscription;

  apiObject: any;
  pairData = {
    currency: '',
    market: ''
  };
  siteMode: string = '';
  themeName: string = '';
  connectionSocketSub: Subscription = new Subscription();
  showTradingViewChart: boolean = false;
  isWebsocketConnected: boolean = false;
  lang: any;

  constructor(
    public _httpservice: HttpserviceComponent,
    public datafeedService: DatafeedService,
    public websocketservice: WebsocketService,
    public market_service: marketService,
    public languageService: LanguageService,
    private translate: TranslateService,
    public orderbookService: OrderbookService
  ) {
    this.languageService.language.subscribe((res) => {
      this.lang = res;
    });
  }

  @Input()
  set symbol(symbol: ChartingLibraryWidgetOptions["symbol"]) {
    this._symbol = symbol || this._symbol;
  }

  @Input()
  set interval(interval: ChartingLibraryWidgetOptions["interval"]) {
    this._interval = interval || this._interval;
  }

  @Input()
  set datafeedUrl(datafeedUrl: string) {
    this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  }

  @Input()
  set libraryPath(libraryPath: ChartingLibraryWidgetOptions["library_path"]) {
    this._libraryPath = libraryPath || this._libraryPath;
  }

  @Input()
  set chartsStorageUrl(
    chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"]
  ) {
    this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
  }

  @Input()
  set chartsStorageApiVersion(
    chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"]
  ) {
    this._chartsStorageApiVersion =
      chartsStorageApiVersion || this._chartsStorageApiVersion;
  }

  @Input()
  set clientId(clientId: ChartingLibraryWidgetOptions["client_id"]) {
    this._clientId = clientId || this._clientId;
  }

  @Input()
  set userId(userId: ChartingLibraryWidgetOptions["user_id"]) {
    this._userId = userId || this._userId;
  }

  @Input()
  set fullscreen(fullscreen: ChartingLibraryWidgetOptions["fullscreen"]) {
    this._fullscreen = fullscreen || this._fullscreen;
  }

  @Input()
  set autosize(autosize: ChartingLibraryWidgetOptions["autosize"]) {
    this._autosize = autosize || this._autosize;
  }

  @Input()
  set containerId(containerId: ChartingLibraryWidgetOptions["container"]) {
    this._containerId = containerId || this._containerId;
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.

  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    combineLatest([this._httpservice.currentTheme, this._httpservice.ChartColor, this._httpservice.currentMarketPair, this._httpservice.pricePrecision]).subscribe(([theme, mode, pair, precision]) => {
      if (theme !== '' && mode !== '' && !_.isEmpty(pair) && !_.isEmpty(precision)) {
        this.themeName = theme;
        this.siteMode = mode;
        this.pairData.currency = pair.currency;
        this.pairData.market = pair.market;
        this.currentPrecision = precision;
        this.callUpdateChart();
      }
    });

    this.connectionSocketSub = this.websocketservice.connectingsocket.subscribe((result) => {
      if (result == true) {
        this.isWebsocketConnected = result;
        this.callUpdateChart();
      } else {
        this.isWebsocketConnected = result;
      }
    });
  }

  callUpdateChart() {
    if (this.themeName !== '' && this.siteMode !== '' && (this.pairData.currency !== "" && this.pairData.market !== "") && !_.isEmpty(this.currentPrecision) && this.isWebsocketConnected) {
      if (this.siteMode == "Day") {
        this.themeMode = "Light";
        this.updateChart(this.pairData);
      } else if (this.siteMode == "Night") {
        this.themeMode = "Dark";
        this.updateChart(this.pairData);
      }
    }
  }

  updateChart(data: any) {
    if (this._tempSymbol == "") {
      this._symbol = data.currency + "/" + data.market;
      this._tempSymbol = this._symbol;
      this.loadChart();
      return;
    }
    if (this._tvWidget != null && !_.isEmpty(this._tvWidget)) {
      this._tvWidget.onChartReady(() => {
        this._symbol = data.currency + "/" + data.market;
        if (this._symbol !== undefined && this._symbol !== '') {
          this._tvWidget!.activeChart()?.setSymbol(this._symbol);
        }
        this._tvWidget!.changeTheme(this.themeMode).then(() => {
          if (this._tvWidget) {
            this._tvWidget.applyOverrides(this.getOverrides());
          }
        });
      })
    }

  }

  loadChart() {
    if (this.themeName == '' || this.getOverrides()['paneProperties.background'] == '') {
      setTimeout(() => {
        this.loadChart();
      }, 1000);
      return;
    }

    function getLanguageFromURL(): LanguageCode | null {
      const regex = new RegExp('[\\?&]lang=([^&#]*)');
      const results = regex.exec(location.search);

      return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode;
    }

    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._symbol,
      datafeed: this.datafeedService.getChartData(),
      interval: this._interval,
      container: this._containerId,
      library_path: this._libraryPath,
      locale: getLanguageFromURL() || "en",
      disabled_features: [
        "use_localstorage_for_settings",
        "header_saveload",
        "header_compare",
        "header_undo_redo",
        "header_symbol_search",
        "go_to_date",
        "time_frames",
      ],
      enabled_features: [
        'study_templates',
        "header_in_fullscreen_mode",
        "side_toolbar_in_fullscreen_mode"
      ],
      charts_storage_url: this._chartsStorageUrl,
      charts_storage_api_version: this._chartsStorageApiVersion,
      client_id: this._clientId,
      user_id: this._userId,
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      theme: this.themeMode,
      overrides: this.getOverrides(),
      custom_css_url: `/assets/css/${this.themeName}`,
    };
    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      tvWidget.activeChart().createStudy('Moving Average', false, false, [25]);
      tvWidget.activeChart().createStudy('Moving Average', false, false, [99]);
      tvWidget.applyOverrides(this.getOverrides());
      // this.showOpenOrders();
    });
  }

  getOverrides() {
    this.backgroundColor2 = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-bg-secondary")
      .trim();
    this.backgroundColor = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-card-bg")
      .trim();
    this.bidColor = getComputedStyle(document.body, null)
      .getPropertyValue("--success")
      .trim();
    this.askColor = getComputedStyle(document.body, null)
      .getPropertyValue("--danger")
      .trim();
    this.textColor = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-text")
      .trim();
    this.borderColor = getComputedStyle(document.body, null)
      .getPropertyValue("--rx-border-color")
      .trim();

    let precision = '';
    if (this.currentPrecision?.pricePlaceholder.toString().includes('.')) {
      precision = this.currentPrecision?.pricePlaceholder.toString().split('.')[1];
    }

    return {
      "paneProperties.background": this.backgroundColor,
      "paneProperties.backgroundType": "solid",
      "paneProperties.horzGridProperties.color": this.borderColor,
      "paneProperties.vertGridProperties.color": this.borderColor,
      "scalesProperties.textColor": this.textColor,
      'mainSeriesProperties.minTick': `1${precision},1,false`
    };
  }

  // showOpenOrders() {
  //   this.openOrdersSub = this.websocketservice.PendingOrders.subscribe((data: any) => {
  //     console.log('B', data);
  //     if (data != null && data !== undefined && data.length !== 0) {
  //       data.forEach((element: any) => {
  //         // Split the base and quote currencies
  //         const bcurr = element[2].split('_');
  //         element.base = bcurr[0];
  //         element.quote = bcurr[1];

  //         if (element[10] === true) {
  //           // Check for existing orders with the same rate
  //           const matchingOrders = this.OpenOrders.filter(
  //             (order: any) => order.rate === element[3]
  //           );

  //           if (matchingOrders.length > 0) {
  //             // Aggregate pending values for orders with the same rate
  //             const totalPending = matchingOrders.reduce(
  //               (sum: number, order: any) => sum + order.pending,
  //               element[5] // Start with the current element's pending value
  //             );

  //             // Remove all orders with the same rate
  //             this.OpenOrders = this.OpenOrders.filter(
  //               (order: any) => order.rate !== element[3]
  //             );

  //             // Add a new aggregated order
  //             const newOrder = {
  //               base: element.base,
  //               order_id: element[0], // Use the current element's order_id
  //               pending: totalPending, // Use the aggregated pending value
  //               quote: element.quote,
  //               rate: element[3],
  //               side: element[7],
  //               status: element[10],
  //               stop_price: element[8],
  //               timestamp: element[9],
  //               type: element[6],
  //               user_id: element[1],
  //               volume: element[4],
  //               stop_activation: element[12],
  //             };
  //             this.OpenOrders.unshift(newOrder);
  //           } else {
  //             // If no matching orders, simply add the new order
  //             const newOrder = {
  //               base: element.base,
  //               order_id: element[0],
  //               pending: element[5],
  //               quote: element.quote,
  //               rate: element[3],
  //               side: element[7],
  //               status: element[10],
  //               stop_price: element[8],
  //               timestamp: element[9],
  //               type: element[6],
  //               user_id: element[1],
  //               volume: element[4],
  //               stop_activation: element[12],
  //             };
  //             this.OpenOrders.unshift(newOrder);
  //           }
  //         } else if (element[10] === false) {
  //           // Subtract pending value for orders with the same rate
  //           const matchingOrder = this.OpenOrders.find(
  //             (order: any) => order.rate === element[3]
  //           );

  //           if (matchingOrder) {
  //             matchingOrder.pending -= element[5]; // Subtract the pending value

  //             if (matchingOrder.pending <= 0) {
  //               // Remove the order if pending value becomes zero or less
  //               const index = this.OpenOrders.findIndex(
  //                 (order: any) => order.rate === element[3]
  //               );
  //               if (index !== -1) {
  //                 this.OpenOrders.splice(index, 1);
  //               }
  //             }
  //           }
  //         } else {
  //           // If the order is not active, remove it from OpenOrders
  //           const index = this.OpenOrders.findIndex(
  //             (order: any) => order.rate === element[3]
  //           );
  //           if (index !== -1) {
  //             this.OpenOrders.splice(index, 1);
  //           }
  //         }
  //       });
  //       this.OpenOrders.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1);
  //       if (this.idToApiObject.size > 0) {
  //         this.idToApiObject.forEach((_value, key) => {
  //           this.idToApiObject.get(key).remove();
  //           this.idToApiObject.delete(key);
  //         });
  //       }
  //       this.addOpenOrders();
  //     } else {
  //       this.OpenOrders = [];
  //       if (this.idToApiObject.size > 0) {
  //         this.idToApiObject.forEach((_value, key) => {
  //           this.idToApiObject.get(key).remove();
  //           this.idToApiObject.delete(key);
  //         });
  //       }
  //     }
  //   });
  // }

  // addOpenOrders() {
  //   if (this._tvWidget != null && !_.isEmpty(this._tvWidget)) {
  //     this._tvWidget.onChartReady(() => {
  //       if (!_.isEmpty(this.OpenOrders)) {
  //         this.OpenOrders.forEach((element: any) => {
  //           if (element.side == 1) {
  //             if (this._tvWidget != null && !_.isEmpty(this._tvWidget)) {
  //               let apiObject = this._tvWidget.activeChart().createOrderLine()
  //                 .setBodyBackgroundColor(getComputedStyle(document.body, null).getPropertyValue("--success").trim())
  //                 .setBodyBorderColor(getComputedStyle(document.body, null).getPropertyValue("--success").trim())
  //                 .setBodyTextColor('#ffffff')
  //                 .setLineColor(getComputedStyle(document.body, null).getPropertyValue("--success").trim())
  //                 .setQuantityBorderColor(getComputedStyle(document.body, null).getPropertyValue("--success").trim())
  //                 .setQuantityBackgroundColor('#000000')
  //                 .setCancelButtonBackgroundColor('#000000')
  //                 .setCancelButtonBorderColor(getComputedStyle(document.body, null).getPropertyValue("--success").trim())
  //                 .setCancelButtonIconColor('#ffffff')
  //                 .setLineStyle(2)
  //                 // .onCancel("onCancel called", function (text) {
  //                 // })
  //                 .setText(element.type == 1 ? this.translate.instant('Exchange.Form.Market') : element.type == 2 ? this.translate.instant('Exchange.Form.Limit')
  //                   : element.type == 3 ? this.translate.instant('Exchange.Form.StopMarket') : element.type == 4 ? this.translate.instant('Exchange.Form.StopLimit')
  //                     : element.type == 5 ? this.translate.instant('Exchange.Form.DayOnly') : element.type == 6 ? this.translate.instant('Exchange.Form.FillOrKill')
  //                       : element.type == 7 ? this.translate.instant('Exchange.Form.ImmediateOrCancel') : '')
  //                 .setQuantity(element.pending)
  //                 .setPrice(element.rate);
  //               this.idToApiObject.set(element.rate, apiObject);
  //             }
  //           }
  //           if (element.side == 2) {
  //             if (this._tvWidget != null && !_.isEmpty(this._tvWidget)) {
  //               let apiObject = this._tvWidget.activeChart().createOrderLine()
  //                 .setBodyBackgroundColor(getComputedStyle(document.body, null).getPropertyValue("--danger").trim())
  //                 .setBodyBorderColor(getComputedStyle(document.body, null).getPropertyValue("--danger").trim())
  //                 .setBodyTextColor('#ffffff')
  //                 .setLineColor(getComputedStyle(document.body, null).getPropertyValue("--danger").trim())
  //                 .setQuantityBorderColor(getComputedStyle(document.body, null).getPropertyValue("--danger").trim())
  //                 .setQuantityBackgroundColor('#000000')
  //                 .setCancelButtonBackgroundColor('#000000')
  //                 .setCancelButtonBorderColor(getComputedStyle(document.body, null).getPropertyValue("--danger").trim())
  //                 .setCancelButtonIconColor('#ffffff')
  //                 .setLineStyle(2)
  //                 // .onCancel("onCancel called", function (text) {
    
  //                 // })
  //                 .setText(element.type == 1 ? this.translate.instant('Exchange.Form.Market') : element.type == 2 ? this.translate.instant('Exchange.Form.Limit')
  //                   : element.type == 3 ? this.translate.instant('Exchange.Form.StopMarket') : element.type == 4 ? this.translate.instant('Exchange.Form.StopLimit')
  //                     : element.type == 5 ? this.translate.instant('Exchange.Form.DayOnly') : element.type == 6 ? this.translate.instant('Exchange.Form.FillOrKill')
  //                       : element.type == 7 ? this.translate.instant('Exchange.Form.ImmediateOrCancel') : '')
  //                 .setQuantity(element.pending)
  //                 .setPrice(element.rate);
  //               this.idToApiObject.set(element.rate, apiObject);
  //             }
  //           }
  //         });
  //       }
  //     });
  //   }

  // }

  ngOnDestroy() {
    if (this._tvWidget !== null) {
      this._tvWidget.remove();
      this._tvWidget = null;
    }

    if (this.chartcolorSub) {
      this.chartcolorSub.unsubscribe();
    }

    if (this.currentPairsSub) {
      this.currentPairsSub.unsubscribe();
    }

    if (this.currentThemeSub) {
      this.currentThemeSub.unsubscribe();
    }

    if (this.pricePrecisionSub) {
      this.pricePrecisionSub.unsubscribe();
    }

    if (this.openOrdersSub) {
      this.openOrdersSub.unsubscribe()
    }

    if (this.connectionSocketSub) {
      this.connectionSocketSub.unsubscribe();
    }
  }
}
