import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { AuthService } from "../guards/auth.service";
import "rxjs/add/operator/do";
import { Router } from "@angular/router";
import { LanguageService } from "../_httpservices/API/language.service";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { RxToastrService } from "../_httpservices/rx-toastr.service";
import { KycRequiredModalService } from "../components/kyc-required-modal/kyc-required-modal.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  lang: any;
  urlarray = ["customer-favourite-pairs", "open-offersorders-count", "risk-rules", "my-orders"];
  constructor(
    public authService: AuthService,
    public kycRequiredModalService: KycRequiredModalService,
    private rxToastrService: RxToastrService,
    public router: Router,
    private languageService: LanguageService,
    private translate: TranslateService
  ) {
    this.languageService.language.subscribe((res) => {
      this.lang = res;
    });
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        var patharr = request.urlWithParams.split('/');
        var path = patharr[patharr.length - 1];
        if ([401, 403].includes(err.status) && this.urlarray.includes(path) == false) {
          // auto logout if 401 or 403 response returned from api
          this.authService.logout();
        }

        if ([404].includes(err.status)) {
          this.router.navigate(["404"]);
        }

        if ([500].includes(err.status)) {
          this.rxToastrService.showRxToastr("", this.translate.instant(`errors.INTERNAL_SERVER_ERROR`), "", "danger");
          return throwError(err);
        }

        if (patharr[3] == 'social-verification') {
          return throwError(err);
        }

        if (request.urlWithParams.includes('open-offersorders-count')) {
          return throwError(err);
        }

        if (!err.error.hasOwnProperty('Message')) {
          return throwError(err);
        }

        if (err.error.Message == 'Invalid token.') {
          return throwError(err);
        }

        if (err.error.Message == 'BROKER_NOT_FOUND') {
          return throwError(err);
        }

        if (err.error.Message == 'KYC_NOT_APPROVED') {
          this.kycRequiredModalService.showKycRequiredModal.next(true);
          return throwError(err);
        }

        if (err.error.Message == 'ACCOUNT_FROZEN_COOL_OFF_48_HRS') {
          this.rxToastrService.showRxToastr("", "", this.translate.instant(`errors.${err.error.Message}`), "danger");
          return throwError(err);
        }

        this.rxToastrService.showRxToastr("", this.translate.instant(`errors.${err.error.Message}`), "", "danger");

        return throwError(err);
      })
    );
  }
}
