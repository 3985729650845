import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { AuthService } from './guards/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { ExchangeComponent } from './modules/exchange/exchange/exchange.component';
import { OrdersBookComponent } from './modules/exchange/orders-book/orders-book.component';
import { AllOpenOrdersComponent } from './modules/exchange/all-open-orders/all-open-orders.component';
import { SmallChartComponent } from './modules/exchange/small-chart/small-chart.component';
import { TradeFeeComponent } from './modules/exchange/trade-fee/trade-fee.component';
import { MaintenanceComponent } from './modules/maintenance/maintenance.component';
import { OfficialVerificationComponent } from './modules/official-verification/official-verification.component';
import { AdditionalInformationComponent } from './modules/additional-information/additional-information.component';
import { AcceptSubaccountComponent } from './modules/accept-subaccount/accept-subaccount.component';
import { PersonalVerificationComponent } from './modules/personal-verification/personal-verification.component';
import { PgComponent } from './modules/pg/pg.component';
import { BusinessKycComponent } from './modules/business-kyc/business-kyc.component';
import { ExchangeResolver } from './_httpservices/exchange.resolver';
import { ConvertSmallBalanceComponent } from './modules/convert-small-balance/convert-small-balance.component';
import { AnnouncementsComponent } from './modules/announcements/announcements.component';
import { AutoBuyComponent } from './modules/auto-buy/auto-buy.component';
import { MarketsComponent } from './modules/markets/markets.component';
import { MobileVerifyComponent } from './modules/mobile-verify/mobile-verify.component';

const routes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent,
    canActivate: [], // Should be replaced with actual auth guard
    children: [
      { path: '', component: MarketsComponent },
      { path: 'markets', component: MarketsComponent },
      { path: 'exchange', component: ExchangeComponent, resolve: { exchange: ExchangeResolver } },
      {
        path: 'exchange/:pair',
        component: ExchangeComponent,
        resolve: {
          exchange: ExchangeResolver
        },
        children: [{
          path: 'Orders-Book',
          component: OrdersBookComponent,
        }, {
          path: 'All-Open-Orders',
          component: AllOpenOrdersComponent,
        }, {
          path: 'Orders-Form',
          loadChildren: () => import('./modules/exchange/orders-form/orders-form.module').then(m => m.OrdersFormModule),
        }, {
          path: 'Small-Chart',
          component: SmallChartComponent,
        }, {
          path: 'Trade-Fee',
          component: TradeFeeComponent,
        },
        ]
      },
      // { path: 'futures', loadChildren: () => import('./modules/futures/futures.module').then(m => m.FuturesModule) },
      // { path: 'futures/:contract', loadChildren: () => import('./modules/futures/futures.module').then(m => m.FuturesModule) },
      { path: 'convert', loadChildren: () => import('./modules/./otc/otc.module').then(m => m.OtcModule) },
      { path: 'funds', loadChildren: () => import('./modules/./funds/funds.module').then(m => m.FundsModule), canActivate: [AuthGuard] },
      { path: 'orders', loadChildren: () => import('./modules/orders/orders.module').then(m => m.OrdersModule), canActivate: [AuthGuard] },
      { path: 'affiliates', loadChildren: () => import('./modules/affiliates/affiliates.module').then(m => m.AffiliatesModule), canActivate: [AuthGuard] },
      { path: 'discounts', loadChildren: () => import('./modules/mdx-coin/mdx-coin.module').then(m => m.MdxCoinModule), canActivate: [AuthGuard] },
      { path: 'settings', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },
      { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
      { path: 'signup', loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule) },
      { path: 'forgot-password', loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
      { path: 'account-verification', loadChildren: () => import('./modules/account-verify/account-verify.module').then(m => m.AccountVerifyModule) },
      { path: 'account-verification/:Id', loadChildren: () => import('./modules/account-verify/account-verify.module').then(m => m.AccountVerifyModule) },
      { path: 'reset-password', loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
      { path: 'reset-password/:Id', loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule) },
      { path: 'legal', loadChildren: () => import('./modules/legal/legal.module').then(m => m.LegalModule) },
      { path: 'AboutUs', loadChildren: () => import('./modules/legal-documents/about-us/about-us.module').then(m => m.AboutUsModule) },
      { path: 'ContactUs', loadChildren: () => import('./modules/legal-documents/contact-us/contact-us.module').then(m => m.ContactUsModule) },
      { path: 'CookiePolicy', loadChildren: () => import('./modules/legal-documents/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule) },
      // { path: 'RiskDisclosure', loadChildren: './modules/legal-documents/risk-discloser/risk-discloser.module#RiskDiscloserModule' },
      // { path: 'Terms', loadChildren: './modules/legal-documents/terms/terms.module#TermsModule' },
      { path: 'exchange-fee', loadChildren: () => import('./modules/legal-documents/exchange-fee/exchange-fee.module').then(m => m.ExchangeFeeModule) },
      { path: 'convert_to_dust', loadChildren: () => import('./modules/convert/convert.module').then(m => m.ConvertModule) },
      { path: 'withdrawal-request', loadChildren: () => import('./modules/withdrawal-request/withdrawal-request.module').then(m => m.WithdrawalRequestModule) },
      { path: 'withdrawal-request/:Id', loadChildren: () => import('./modules/withdrawal-request/withdrawal-request.module').then(m => m.WithdrawalRequestModule) },
      // { path: 'redirect-customer', loadChildren: './redirect-customer/redirect-customer.module#RedirectCustomerModule' },
      // { path: 'mobile-verify', loadChildren: './mobile-verify/mobile-verify.module#MobileVerifyModule',  canActivate: [AuthGuard]  },
      { path: 'bank', loadChildren: () => import('./modules/bank/bank.module').then(m => m.BankModule), canActivate: [AuthGuard] },
      { path: 'profile-verification', component: PersonalVerificationComponent},
      { path: 'KYC', loadChildren: () => import('./modules/kyc/kyc.module').then(m => m.KYCModule), canActivate: [AuthGuard] },
      { path: 'redirect-customer', loadChildren: () => import('./modules/redirect-customer/redirect-customer.module').then(m => m.RedirectCustomerModule) },
      { path: 'ref/:id', loadChildren: () => import('./modules/referral/referral.module').then(m => m.ReferralModule) },
      { path: 'block-account/:Id', loadChildren: () => import('./modules/block-accont/block-accont.module').then(m => m.BlockAccontModule) },
      { path: 'stake', loadChildren: () => import('./modules/evp/evp.module').then(m => m.EvpModule) },
      { path: 'p2p', loadChildren: () => import('./modules/p2p/p2p.module').then(m => m.P2pModule) },
      { path: 'buy-crypto', loadChildren: () => import('./modules/buycrypto/buycrypto.module').then(m => m.BuycryptoModule) },
      { path: 'official-verification', component: OfficialVerificationComponent },
      { path: 'launchpad', loadChildren: () => import('./modules/launchpad/launchpad.module').then(m => m.LaunchpadModule) },
      { path: 'market-replay', loadChildren: () => import('./modules/market-replay/market-replay.module').then(m => m.MarketReplayModule) },
      { path: 'sub-accounts', loadChildren: () => import('./modules/sub-account/sub-account.module').then(m => m.SubAccountModule) },
      { path: 'limits', loadChildren: () => import('./modules/legal-documents/limits/limits.module').then(m => m.LimitsModule), canActivate: [AuthGuard] },
      { path: 'business-kyc', component: BusinessKycComponent },
      { path: 'grid-trading', loadChildren: () => import('./modules/grid-trading/grid-trading.module').then(m => m.GridTradingModule) },
      { path: 'convert-small-balance', component: ConvertSmallBalanceComponent, canActivate: [AuthGuard] },
      { path: 'gift-cards', loadChildren: () => import('./modules/gift-cards/gift-cards.module').then(m => m.GiftCardsModule) },
      { path: 'announcements', component: AnnouncementsComponent },
      { path: 'auto-buy',  loadChildren: () => import('./modules/auto-buy/auto-buy.module').then(m => m.AutoBuyModule),  canActivate: [AuthGuard]  },
      { path: 'tickets',  loadChildren: () => import('./modules/tickets/tickets.module').then(m => m.TicketsModule),  canActivate: [AuthGuard]  },
      { path: 'phone-verification', component: MobileVerifyComponent, canActivate: [AuthGuard]  },
    ]
  },
  { path: 'pg/:type/:asset', component: PgComponent },
  { path: 'maintenance', component: MaintenanceComponent},
  { path: 'addon-info', component: AdditionalInformationComponent, canActivate: [AuthGuard] },
  { path: 'accept/:id', component: AcceptSubaccountComponent, canActivate: [AuthGuard]},
  { path: '**', loadChildren: () => import('./response-error/response-error.module').then(m => m.ResponseErrorModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthService, AuthGuard],
})
export class AppRoutingModule { }
