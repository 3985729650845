declare var baseUrl: any;
import { Observable, Subject, BehaviorSubject, Subscription } from "rxjs";
import { Injectable, NgZone, OnInit } from "@angular/core";

import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import "rxjs/add/observable/of";
import { HttpserviceComponent } from "./API/httpservice.component";
import * as _ from "lodash";
import { AuthService } from "../guards/auth.service";

@Injectable({
  providedIn: "root",
})
export class WebsocketService {
  socket: any;

  CurrentMarketCurrencyPair: string[] = [];
  serverUrl: any;
  isSocketConnected = false;
  CurrentMarketCurrencyPairs = new BehaviorSubject<any>([]);
  DataAllMarket = new BehaviorSubject<any>([]);
  allOpenOrders = new BehaviorSubject<any>([]);
  AllMatchedData = new BehaviorSubject<any>([]);
  PendingOrders = new BehaviorSubject<any>([]);
  datacharts = new BehaviorSubject<any>([]);
  AllBalances = new BehaviorSubject<any>([]);
  CurrURL = new BehaviorSubject<any>([]);
  CurrWalletBalance = new BehaviorSubject<any>([]);
  RefreshBalance_InterVAl = undefined;
  CryptoPrice = new BehaviorSubject<any>([]);
  FiatPrice = new BehaviorSubject<any>([]);
  connectingsocket = new BehaviorSubject<boolean>(false);

  tempContractName: any;
  tempfunddata: any[] = [];
  currentPairs: any;
  private sub = new Subject();
  subj$ = this.sub.asObservable();
  walletbalance: any[] = [];

  loginStatus: any;
  chartResolution: string = "60";
  oldResolution: string = "";
  oldPair: any;
  marketPair = {
    currency: "",
    market: "",
  };
  symbolInfoData = new BehaviorSubject<any>([]);

  constructor(
    private route: ActivatedRoute,
    public _httpservice: HttpserviceComponent,
    public router: Router,
    public zone: NgZone,
    private authService: AuthService
  ) {
    this.serverUrl = baseUrl
      .replace("https://", "wss://")
      .replace("http://", "ws://");
    this.connect();

    this.authService.userSubject.subscribe((data: any) => {
      this.loginStatus = !_.isNull(data) && !_.isEqual(data, "") ? true : false;
      if (this.loginStatus == true) {
        this.loginSocket(localStorage.getItem("Authorization")!);
        this.authBalSubcribe();
      }
    });

    this.getfiatPriceUSD();
  }

  orderbookdata(value: any) {
    this.sub.next(value);
  }

  CurrURLName(val: any) {
    this.CurrURL.next(val);
  }

  WalletBalance(bal: any) {
    this.CurrWalletBalance.next(bal);
  }

  _RefreshBalance() {
    if (this.router.url.split("/")[1] === "exchange") {
      const SelectedCurrency = this.marketPair;
      // console.log('SelectedCurrency', SelectedCurrency);
      if (SelectedCurrency === undefined || SelectedCurrency == null) {
        return;
      }
      const Balance = {
        Market: 0,
        Currency: 0,
      };

      let response = [];
      this._httpservice._walletALlBalance.subscribe((data: any) => {
        this.zone.run(() => {
          response = data;
          response.forEach((element: any) => {
            if (SelectedCurrency.currency === element.currency) {
              Balance.Currency = element.balance;
            } else if (SelectedCurrency.market === element.currency) {
              Balance.Market = element.balance;
            }
          });
          // console.log('Get FiatCurrency', data);
          this.CurrWalletBalance.next(Balance);
        });
      });
      // console.log('wallet api response', response);
    }
  }

  async getfiatPriceUSD() {
    let response = await this._httpservice
      .GetFiatPriceToUSD()
      .catch((err) => { });
    if (response == undefined || response == null) {
      return;
    }
    this.FiatPrice.next(response);
  }


  connect() {
    if (!this.serverUrl) {
      alert("invalid server Url");
      return;
    }

    const url = this.serverUrl + "streams";

    if (this.socket) {
      this.socket.close();
      this.socket = undefined;
    }

    this.socket = new WebSocket(url);

    this.socket.onopen = (event: any) => {
      // console.log('%c opened connection to ' + url, 'color: green;');
      this.isSocketConnected = true;
      this.connectingsocket.next(this.isSocketConnected);
      this.subscribeChannel();
      this._httpservice.currentMarketPair.subscribe((data: any) => {
        if (!_.isEmpty(data)) {
          this.marketPair.currency = data.currency;
          this.marketPair.market = data.market;
          this.currentPairs =
            this.marketPair.currency + "_" + this.marketPair.market;
          this.SocketSubcribe(this.currentPairs);
          this.authBalSubcribe();
          if (this.loginStatus) {
            this.authOrderSubcribe(this.currentPairs);
          }
        }
      });

      this.DefaultSubscribe();
      if (this.loginStatus) {
        this.loginSocket(localStorage.getItem("Authorization")!);
      }
    };

    this.socket.onclose = (event: any) => {
      // console.log('%c closed connection from ' + url, 'color: red;');
      this.socket = undefined;
      this.isSocketConnected = false
      this.connectingsocket.next(this.isSocketConnected);
      if (this.isSocketConnected == false) {
        setTimeout(() => {
          this.connect();
        }, 5000);
      }
    };

    this.socket.onmessage = (event: any) => {
      const parsedData = JSON.parse(event.data);

      if (parsedData.method.includes("unsubscribe")) {
        parsedData.response.forEach((el: any) => {
          // if (el.channel.includes("books")) {
          //   this.allOpenOrders.next([]);
          // }

          if (el.channel.includes("trades")) {
            this.AllMatchedData.next([]);
          }

          if (el.channel.includes("orders")) {
            this.PendingOrders.next([]);
          }

          if (el.channel.includes("wallets")) {
            this.AllBalances.next([]);
            this._httpservice.updatebal([]);
          }
        });
      }

      if (parsedData.channel) {
        if (
          parsedData.channel != "tickers" &&
          parsedData.channel != "crypto-prices" &&
          parsedData.channel != "wallets" &&
          !parsedData.channel.includes("ALL")
        ) {
          if (parsedData.channel.includes(this.currentPairs) == false) {
            // unsubribe and ignore the packet
            const obj2 = {};
            // @ts-ignore: Unreachable code error
            obj2["op"] = "unsubscribe";
            // @ts-ignore: Unreachable code error
            obj2["args"] = [parsedData.channel];
            this.send(obj2);
            return;
          }
        }
        if (parsedData.channel == "tickers") {
          const marketData = parsedData.data;
          this.DataAllMarket.next(marketData);
        } else if (parsedData.channel.includes("books")) {
          const books = parsedData.data;
          this.allOpenOrders.next(books);
        } else if (parsedData.channel.includes("trades")) {
          const allMatchedDataTemp = parsedData.data;
          this.AllMatchedData.next(allMatchedDataTemp);
        } else if (parsedData.channel.includes("orders")) {
          const OpenOrders = parsedData.data;
          this.PendingOrders.next(OpenOrders);
        } else if (parsedData.channel.includes("kline")) {
          this.datacharts.next(parsedData);
        } else if (parsedData.channel == "wallets") {
          const allbalance = parsedData.data;
          // console.log(Object.keys(allbalance[0]).length);
          if (Object.keys(allbalance[0]).length > 1) {
            this.tempfunddata = [];
            Object.entries(allbalance[0]).forEach(([key, value]) => {
              // console.log(key, value)
              if (allbalance) {
              }
              let obj: any = {};

              obj["currency"] = key;
              // @ts-ignore
              obj["balance"] = value[0];
              // @ts-ignore
              obj["balanceInTrade"] = value[1];
              // @ts-ignore
              obj["staked"] = value[2];
              // value['balance_in_trade']
              // @ts-ignore
              obj["bonus"] = value[3];

              this.tempfunddata.unshift(obj);
              // console.log('tempfunddata', tempfunddata);
            });
          } else {
            // console.log(this.tempfunddata);
            this.tempfunddata.forEach((element) => {
              Object.entries(allbalance[0]).forEach(([key, value]) => {
                if (element.currency == key) {
                  // console.log('-------->', key, value)
                  // @ts-ignore
                  element.balance = value[0];
                  // @ts-ignore
                  element.balanceInTrade = value[1];
                  // @ts-ignore
                  element.staked = value[2];
                  // @ts-ignore
                  element.bonus = value[3];
                }
              });
            });
          }

          // allbalance.forEach((el:any) => {

          // });

          // if (this.walletbalance != undefined && this.walletbalance != null && this.walletbalance.length != 0) {
          //   this.walletbalance.forEach((element:any) => {
          //     // console.log('funddata2', tempfunddata);
          //     if (element.currency == this.tempfunddata[0].currency) {
          //       element.balance = this.tempfunddata[0].balance;
          //       element.balanceInTrade = this.tempfunddata[0].balanceInTrade;
          //     }
          //   });
          // } else {
          // console.log('this.walletbalance', this.tempfunddata);
          this.walletbalance = [];
          this.walletbalance = this.tempfunddata;
          // console.log('funddata2', this.walletbalance);
          // }
          this.AllBalances.next(this.walletbalance);
          this._httpservice.updatebal(this.walletbalance);
          this._RefreshBalance();
        } else if (parsedData.channel == "crypto-prices") {
          this.CryptoPrice.next(parsedData.data);
        } else {
          // console.warn('unknown', event.data);
        }
        return false;
      }
    };

    this.socket.onerror = (event: any) => {
      console.log("error: " + event.data);
    };
  }

  subscribeChannel() {
    const obj: any = {};
    obj["method"] = "subscribe",
      obj["channels"] = [
        "crypto-prices"
      ];
    this.send(obj);
  }

  send(obj: any) {
    // console.log('hi',obj);
    if (this.isSocketConnected != false) {
      try {
        this.socket.send(JSON.stringify(obj));
      } catch (e) {
        console.log("exception while sending", e);
        this.socket = null;
        // this.connect();
      }
    }
  }

  DefaultSubscribe() {
    // console.log('testing', this.supportedEvents[fundingrate]);
    const obj: any = {};
    obj["method"] = "subscribe",
      obj["channels"] = [
        "tickers"
      ];
    this.send(obj);
  }

  tickerunSubscribe() {
    // console.log('testing', this.supportedEvents[fundingrate]);
    this.DataAllMarket.next([]);
    const obj: any = {};
    (obj["method"] = "unsubscribe"), (obj["channels"] = ["tickers"]);
    this.send(obj);
    this.DefaultSubscribe();
  }

  SocketSubcribe(pair: any) {
    if (this.tempContractName !== null && this.tempContractName !== undefined) {
      const obj2: any = {};
      obj2["method"] = "unsubscribe";
      obj2["channels"] = [
        "books-delta." + this.tempContractName,
        "trades." + this.tempContractName,
        // "kline." + this.tempContractName + '.1',
      ];
      this.send(obj2);
    }
    const obj2: any = {};
    (obj2["method"] = "subscribe"),
      (obj2["channels"] = [
        "books-delta." + pair,
        "trades." + pair,
        // "kline." + pair + '.1',
      ]);
    this.send(obj2);

    this.tempContractName = pair;
    // console.log('tempContractName',this.tempContractName);
  }

  authBalSubcribe() {
    this.walletbalance = [];
    this.tempfunddata = [];
    // console.log('this.tempContractName',this.tempContractName)
    if (this.tempContractName !== undefined && this.tempContractName !== null) {
      const obj2: any = {};
      obj2["method"] = "unsubscribe";
      obj2["channels"] = ["wallets"];
      this.send(obj2);
    }

    const obj2: any = {};
    (obj2["method"] = "subscribe"), (obj2["channels"] = ["wallets"]);
    this.send(obj2);
  }

  authBalUnSubcribe() {
    this.walletbalance = [];
    this.tempfunddata = [];
    const obj2: any = {};
    (obj2["method"] = "unsubscribe"), (obj2["channels"] = ["wallets"]);
    this.send(obj2);
  }

  authOrderSubcribe(pair: any) {
    if (pair != undefined) {
      // console.log('this.tempContractName',this.tempContractName)
      if (
        this.tempContractName !== undefined &&
        this.tempContractName !== null
      ) {
        const obj2: any = {};
        obj2["method"] = "unsubscribe";
        obj2["channels"] = ["orders." + this.tempContractName];
        this.send(obj2);
      }

      const obj2: any = {};
      (obj2["method"] = "subscribe"), (obj2["channels"] = ["orders." + pair]);
      this.send(obj2);
      this.tempContractName = pair;
    } else {
      return;
    }
  }

  loginSocket(bearerToken: string) {
    const obj: any = {};
    obj["method"] = "login";
    obj["token"] = bearerToken;
    this.send(obj);
    this.authBalSubcribe();
    this.authOrderSubcribe(this.currentPairs);
    this.connectingsocket.next(this.isSocketConnected);
  }

  contractChanged(data: any) {
    // console.log(data);
    if (data != null && data !== undefined) {
      // this.changedContractDetails.next(data);
      // this.SocketSubcribe(data);
      // this.socketSubscribeQuery(data.name);
      this.authBalSubcribe();
      // this.authOrderSubcribe(this.currentPairs);
    }
  }

  logOutUnsubscribe() {
    const obj2: any = {};
    obj2["method"] = "unsubscribe";
    obj2["channels"] = ["orders." + this.tempContractName, "wallets"];
    this.send(obj2);
  }

  ALLordersUnsubscribe() {
    const obj2: any = {};
    obj2["method"] = "unsubscribe";
    obj2["channels"] = ["orders.ALL"];
    this.send(obj2);
  }

  logOutaccess() {
    const obj2: any = {};
    obj2["method"] = "logout";
    obj2["channels"] = [];
    this.send(obj2);
  }

  chartSocketSubscribe(pair: any, resolution: any) {
    const obj2: any = {};
    (obj2["method"] = "subscribe"),
      (obj2["channels"] = ["kline." + pair + "." + resolution]);
    this.send(obj2);
  }

  chartSocketUnSubscribe(pair: any, resolution: any) {
    const obj2: any = {};
    obj2["method"] = "unsubscribe";
    obj2["channels"] = ["kline." + pair + "." + resolution];
    this.send(obj2);
  }
}
