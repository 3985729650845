// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.kycModal-text {
    font-size: 1rem;
    line-height: 1.2rem;
}

.modal-content {
    padding: 25px;
    border-radius: 25px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/kyc-required-modal/kyc-required-modal.component.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB","sourcesContent":["\n.kycModal-text {\n    font-size: 1rem;\n    line-height: 1.2rem;\n}\n\n.modal-content {\n    padding: 25px;\n    border-radius: 25px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
